import React from "react";

function L99ComponentCode() {
  return (
    <div>
      {/* L99 Resource and Description New */}
      <div className="lego l099 legoMargin3">
        <div className="legoContainer">
          <div className="imgContainer">
            <a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Sleep+23.pdf">
              <img
                  className=""
                  src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/68b21eb76f767424a65af92427d41025.jpeg"
              />
            </a>
          </div>
          <div className="contentContainer">
            <p className="descriptionHeader">Lifestyle Sleep Health Guide</p>
            <p>As an extremely important part of human health, it’s key to
              understand what Lifestyle Sleep Health is all about. Find out more
              on what can disrupt sleep, tips for better sleep, how to set
              successful sleep goals and sleep recommendations based on your
              age.</p>
            <p>Click image or <a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Sleep+23.pdf">this link</a> to view.</p>
          </div>
        </div>
      </div>
      {/* End L99 Resource and Description New */}
    </div>
  );
}

export default L99ComponentCode;
