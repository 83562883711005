import React from "react";
import { CodeBlock } from "react-code-block";
import { useCopyToClipboard } from "react-use";
import "@fortawesome/fontawesome-svg-core/styles.css";
import YouTube from "react-youtube";

function LegoL99AComponent({ code, language }) {
  code = ` {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a href="">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/a5287dc69aed23322d9756fa8db32851.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Insomnia Coach</p>
                                                <p>A guided, weekly training plan to help you track and improve your
                                                    sleep, which includes a sleep coach with personal feedback about
                                                    your sleep and fun sleep tips and an interactive sleep diary to help
                                                    you keep track of daily changes in your sleep.</p>
                                                <p>Click image or <a
                                                    href="">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description Newe */}`;
  language = "jsx";
  const [state, copyToClipboard] = useCopyToClipboard();

  const copyCode = () => {
    // Logic to copy `code`
    copyToClipboard(code);
  };

  return (
    <CodeBlock code={code} language={language}>
      <div className="relative">
        <CodeBlock.Code className="bg-black">
          <div className="table-row">
            <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
            <CodeBlock.LineContent className="table-cell">
              <CodeBlock.Token />
            </CodeBlock.LineContent>
          </div>
        </CodeBlock.Code>
        <div className="buttonContainer">
          <button className="btn btn-primary ml-auto" onClick={copyCode}>
            <i className="fa-solid fa-copy"></i>{" "}
            {state.value ? "Copied!" : "Copy code"}
          </button>
        </div>
      </div>
    </CodeBlock>
  );
}

export default LegoL99AComponent;
