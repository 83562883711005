import React from "react";
import Container from "react-bootstrap/Container";
import {Nav} from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter6_1 = () => {
    const opts = {
        height: "390",
        width: "640",
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100 legosNewBuild livingWellCenterHeader">
                <section className="mb-0">
                    {/* L6 Background image header bar */}
                    <div className="lego l006 legoMargin5">
                        <div
                            className="backgroundImage"
                            style={{
                                backgroundImage:
                                    "url(" +
                                    "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ea043d0d2f2dadd759a04e88209b8679.jpeg" +
                                    ")",
                            }}
                        >
                            <div
                                className="transparentBarTypeContainer"
                                style={{
                                    backgroundColor: "#FA4E36",
                                }}
                            >
                                &nbsp;&nbsp;
                            </div>
                            <h2 className="l6SubheadWithBackground">Lifestyle as Medicine</h2>
                        </div>
                    </div>
                    {/* End L6 Background image header bar */}
                </section>
                <Container>
                    <div className="w-100">
                        <div className="col-12 m-auto">
                            <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                                <section>
                                    {/* L2 */}
                                    <div className="lego l002 legoMargin2">
                                        <h2 className="l2Headline">What is Lifestyle Medicine?</h2>
                                    </div>
                                    {/* End L2 */}

                                    {/* L14 Paragraph */}
                                    <div className="col-10 m-auto">
                                        <div className="lego l00paragraph ">
                                            <p className="legoMargin2">
                                                Lifestyle medicine is a medical specialty that uses
                                                therapeutic lifestyle interventions as a primary
                                                modality to treat chronic conditions including but not
                                                limited to cardiovascular diseases, type 2 diabetes, and
                                                obesity.
                                            </p>
                                            <p className="legoMargin2">
                                                Lifestyle medicine certified clinicians are trained to
                                                apply evidence-based, whole-person prescriptive
                                                lifestyle change to treat and, when used intensively,
                                                often reverse such conditions.
                                            </p>
                                            <p className="legoMargin3">
                                                Applying the six pillars of lifestyle medicine — a whole
                                                food plant-predominant eating pattern, physical
                                                activity, restorative sleep, stress management,
                                                avoidance of risky substances, and positive social
                                                connections — also provides effective prevention for
                                                these conditions.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L83F Icon Color Bar New */}
                                    <div className="lego l097 legoMargin3">
                                        <div className="imgContainer legoMargin1">
                                            <img
                                                className="icon"
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/c4e728b37d684d8279bcd536dcb5222d.jpeg"
                                            />
                                        </div>
                                        <div className="contentContainer">
                                            <p>
                        <span className="bold ">
                          1. Whole food, plant-predominant nutrition
                        </span>
                                            </p>
                                            <p>
                                                Extensive scientific evidence supports the use of a
                                                whole-food, predominantly plant-based diet is an
                                                important strategy in prevention of chronic disease,
                                                treatment of chronic conditions and, in intensive
                                                therapeutic doses, reversal of chronic illness.
                                            </p>
                                            <p>
                                                Such a diet is rich in fiber, anti-oxidants, and nutrient
                                                dense foods. Choose a variety of minimally processed
                                                vegetables, fruits, whole grains, legumes, nuts and
                                                seeds.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End L83F Icon Color Bar New */}

                                    {/* L83F Icon Color Bar New */}
                                    <div className="lego l097 legoMargin3">
                                        <div className="imgContainer legoMargin1">
                                            <img
                                                className="icon"
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/b6ac0ce7cf19be01d6341f0581155386.jpeg"
                                            />
                                        </div>
                                        <div className="contentContainer">
                                            <p>
                                                <span className="bold ">2. Physical activity</span>
                                            </p>
                                            <p>
                                                Regular and consistent physical activity combats the
                                                negative effects of sedentary behavior. It is
                                                important that adults engage in both general physical
                                                activity as well as purposeful exercise weekly as part
                                                of overall health and resiliency.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End L83F Icon Color Bar New */}
                                    {/* L83F Icon Color Bar New */}
                                    <div className="lego l097 legoMargin3">
                                        <div className="imgContainer legoMargin1">
                                            <img
                                                className="icon"
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7a3f944e2d4119969d65663c9d9a5ecb.jpeg"
                                            />
                                        </div>
                                        <div className="contentContainer">
                                            <p>
                                                <span className="bold ">3. Stress management</span>
                                            </p>
                                            <p>
                                                Stress can lead to improved health and productivity, or
                                                it can lead to anxiety, depression, obesity, immune
                                                dysfunction and more. Helping patients recognize
                                                negative stress responses, identify coping mechanisms
                                                and reduction techniques leads to improved well-being.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End L83F Icon Color Bar New */}
                                    {/* L83F Icon Color Bar New */}
                                    <div className="lego l097 legoMargin3">
                                        <div className="imgContainer legoMargin1">
                                            <img
                                                className="icon"
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/956bba33a4df56079e46c707e4078980.jpeg"
                                            />
                                        </div>
                                        <div className="contentContainer">
                                            <p>
                        <span className="bold ">
                          4. Avoidance of risky substances
                        </span>
                                            </p>
                                            <p>
                                                Use of tobacco and excessive alcohol consumption have
                                                been shown to increase the risk of chronic diseases and
                                                death. Treatments often take time, different
                                                approaches and many attempts. Patients and support
                                                are an important part of breaking risky substance
                                                habits.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End L83F Icon Color Bar New */}
                                    {/* L83F Icon Color Bar New */}
                                    <div className="lego l097 legoMargin3">
                                        <div className="imgContainer legoMargin1">
                                            <img
                                                className="icon"
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7151f0b68c69418af31568c49638e2ee.jpeg"
                                            />
                                        </div>
                                        <div className="contentContainer">
                                            <p>
                                                <span className="bold ">5. Restorative sleep</span>
                                            </p>
                                            <p>
                                                Sleep delays/interruptions have been shown to cause
                                                sluggishness, low attention span, decreased sociability,
                                                depressed mood, decreased deep sleep, decreased caloric
                                                burn during the day, increased hunger and decreased
                                                feeling of fullness, insulin resistance and decreased
                                                performance. Strive for 7 or more hours per night for
                                                optimal health.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End L83F Icon Color Bar New */}
                                    {/* L83F Icon Color Bar New */}
                                    <div className="lego l097 legoMargin5">
                                        <div className="imgContainer legoMargin1">
                                            <img
                                                className="icon"
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/feec0b037f84204dd10bdee0916c8336.jpeg"
                                            />
                                        </div>
                                        <div className="contentContainer">
                                            <p>
                                                <span className="bold ">6. Social connection</span>
                                            </p>
                                            <p>
                                                Positive social connections and relationships affect our
                                                physical, mental and emotional health. Leveraging the
                                                power of relationships and social networks can help
                                                reinforce healthy behaviors.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End L83F Icon Color Bar New */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin5">
                                        <h2 className="subHeadAllCaps">LEARNING MORE ABOUT BETTER SLEEP</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L99 Resource and Description New */}
                                    <div className="lego l099 legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Sleep+23.pdf">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/68b21eb76f767424a65af92427d41025.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Lifestyle Sleep Health Guide</p>
                                                <p>As an extremely important part of human health, it’s key to
                                                    understand what Lifestyle Sleep Health is all about. Find out more
                                                    on what can disrupt sleep, tips for better sleep, how to set
                                                    successful sleep goals and sleep recommendations based on your
                                                    age.</p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Sleep+23.pdf">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99 Resource and Description New */}

                                    {/* L99 Resource and Description New */}
                                    <div className="lego l099 legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Sleep+Hygiene+-+8.5x11.+Printer+Friendly.pdf">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/f8c76e5e148c808af84acfab32e5e3b4.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Sleep Hygiene Made Simple</p>
                                                <p>Practicing quality “sleep hygiene” provides so many positive benefits
                                                    you can take advantage of right away.</p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Sleep+Hygiene+-+8.5x11.+Printer+Friendly.pdf">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99 Resource and Description New */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin5">
                                        <h2 className="subHeadAllCaps">FREE SLEEP HEALTH AND HYGIENE APPS</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://mobile.va.gov/app/insomnia-coach">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/a5287dc69aed23322d9756fa8db32851.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Insomnia Coach</p>
                                                <p>A guided, weekly training plan to help you track and improve your
                                                    sleep, which includes a sleep coach with personal feedback about
                                                    your sleep and fun sleep tips and an interactive sleep diary to help
                                                    you keep track of daily changes in your sleep.</p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://mobile.va.gov/app/insomnia-coach">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description New */}

                                    {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://mobile.va.gov/app/cbt-i-coach">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/54e9403635e00c1886e3907e7aef892e.png"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">CBT-i Coach</p>
                                                <p>For people who are engaged in Cognitive Behavioral Therapy for
                                                    Insomnia with a health provider, or who have experienced symptoms of
                                                    insomnia and would like to improve their sleep habits. The app will
                                                    guide you through the process of learning about sleep, developing
                                                    positive sleep routines, and improving your sleep environment. </p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://mobile.va.gov/app/cbt-i-coach">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description New */}

                                    {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://www.sleepcycle.com">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/23309747d92b4483c1f4becfc0288e53.png"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Sleep Cycle</p>
                                                <p>Sleep better and wake up rested with Sleep Cycle’s smart alarm clock.
                                                    It analyses your sleep patterns and detects snoring, sleep talking,
                                                    coughing, and other sounds. </p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://www.sleepcycle.com">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description New */}

                                    {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://thesleepreset.com/google-lpl?utm_source=google&utm_medium=cpc&utm_campaign=20219059637&utm_content=146546747501&utm_term=sleep%20reset%20app&gad_source=1&gbraid=0AAAAADJhGiB4HpSeAsI6wli3gh4jWAZ5p&gclid=Cj0KCQiAwOe8BhCCARIsAGKeD56Bh5_VqzRZAnRmQwEZE1uUHACFGp3UASlJMAv68mJAl5ql6-LqPR8aAvxBEALw_wcB">
                                                    <img
                                                        className="whiteIcon"
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/b19e8eba303d34d1a1a2e42ea726698c.png"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Sleep Reset</p>
                                                <p>A personalized sleep program designed to help you beat insomnia. Find
                                                    out why you’re not sleeping well, so you can sleep deeply through
                                                    the night without harmful pills, grogginess, or side effects. It
                                                    combines Cognitive and Behavioral Therapy for Insomnia (CBT-I) with
                                                    human coaching from sleep experts to tailor strategies just for
                                                    you.</p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://thesleepreset.com/google-lpl?utm_source=google&utm_medium=cpc&utm_campaign=20219059637&utm_content=146546747501&utm_term=sleep%20reset%20app&gad_source=1&gbraid=0AAAAADJhGiB4HpSeAsI6wli3gh4jWAZ5p&gclid=Cj0KCQiAwOe8BhCCARIsAGKeD56Bh5_VqzRZAnRmQwEZE1uUHACFGp3UASlJMAv68mJAl5ql6-LqPR8aAvxBEALw_wcB">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description New */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin5">
                                        <h2 className="subHeadAllCaps">KEEP A SLEEP LOG</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L99 Resource and Description New */}
                                    <div className="lego l099 legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/4bc561d6c6a8eef51477822c9cca3015.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Sleep Hygiene Made Simple</p>
                                                <p>Practicing quality “sleep hygiene” provides so many positive benefits
                                                    you can take advantage of right away.</p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99 Resource and Description New */}

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p>
                                                <a
                                                    className="specialUseBackToTop"
                                                    onClick={() =>
                                                        window.scrollTo({top: 0, behavior: "smooth"})
                                                    }
                                                >
                                                    BACK TO TOP{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default LivingWellCenter6_1;
