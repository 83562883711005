import {useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import styles from './Home.module.scss';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Cluster from "../cluster/index.js";
import {orbitClusterTileList} from '../../actions/orbitClusterTileList.js';
import {useDispatch, useSelector} from "react-redux";
import {userProfile} from "../../actions/userProfile.js";
import {orbit} from "../../actions/orbit.js";
import {DEPARTMENT_ADMIN, ORBIT_ADMIN, ORGANIZATION_ADMIN, REGULAR_USER, SYS_ADMIN} from "../../constants/roles.js";
import {SelectList} from "../selectList/index.js";
import {diagnosesList} from "../../actions/diagnosisList.js";
import {diagList} from "../../actions/diagList.js";

import {
    setDefaultOrbit,
    setDiagnosticFilterList,
    setDiagnosticId,
    setDiagnosticList,
    setDisplayDiagnostics,
    setDisplayTiles,
    setHome,
    setInitialTopNavDisplay,
    setOrbitList,
    setTermsConditionsCompleted,
    setRefreshTileAttributeList
} from "../../actions/home.js";
import {getUser} from "../../helpers/index.js";
import {HOME, UPDATE_LOGO} from "../../actions/types.js";
import {termsConditions} from "../../actions/termsConditions.js";
import {inviteUser} from "../../actions/inviteUser.js";
import {Link} from "react-router-dom";
import {orbitById} from "../../actions/orbitById.js";


const Home = (props) => {
    const [profileUrl, setProfileUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOrbit, setIsLoadingOrbit] = useState(true);
    const [hasLoadedTiles, setHasLoadedTiles] = useState(false);
    const [isRegularUserSettingsComplete, setIsRegularUserSettingsComplete] = useState(false);
    const [isLoadingTermsConditions, setIsLoadingTermsConditions] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const {
        bodyCss
    } = useSelector(state => state.logo);

    const {
        tileAttributes,
        clusterType,
        useFlexContainer,
        diagnosticId,
        diagnosticFilterList,
        diagnosticList,
        displayDiagnostics,
        displayTiles,
        defaultOrbit,
        orbitList,
        clusterCssArray,
        initialTopNavDisplay,
        termsConditionsCompleted,
        refreshTileAttributeList
    } = useSelector(state => state.home);
    const dispatch = useDispatch();
    let user = JSON.parse(getUser());
    let selectedDiagnosisId = [];
    /*const {defaultOrbit} = useSelector(state => {
        user = JSON.parse(getUser());
        return state.orbit
    });*/

	const currentUser = () => {
		return user;
	}

    const setValue = async (value) => {
        dispatch(setDefaultOrbit(value));
        const orb = await orbitById({orbitId: value}, async (data) => {
            //setList(data);
            dispatch({type: UPDATE_LOGO, payload: {orbitCssClass: data.cssClass}});
            let cssClassProps = document.querySelector("body");

            if (cssClassProps && data.cssClass && data.cssClass.length > 0)
                cssClassProps.className = 'newOrbitStructure contentPage safecare' + ' ' + data.cssClass.join(' ');

            navigate(location.pathname);
        }, 'user/orbit');
        await dispatch(orb);

    }

    const changeTermsConditions = async (e) => {
        const tc = await termsConditions({termsConditionsCompleted: e.target.value === 'on' ? true : false}, (data) => {
            if (data?.error)
                return;

            dispatch(setTermsConditionsCompleted(e.target.value === 'on' ? true : false));
        })
        await dispatch(tc);
    }

    const setDiagnosticValue = (value) => {
        dispatch(setDisplayTiles(true));
        dispatch(setDiagnosticId([value]));
        dispatch(setDisplayDiagnostics(false));
        dispatch(setInitialTopNavDisplay(true));
    }

    const setupPagePropertiesAndAttributes = async () => {
        if ((user.roles[0] === ORGANIZATION_ADMIN ||
            user.roles[0] === SYS_ADMIN ||
            user.roles[0] === DEPARTMENT_ADMIN) && (!defaultOrbit || defaultOrbit.length === 0)) {

            dispatch(setDisplayTiles(false));
            setIsLoadingOrbit(true);
            const orb = await orbit({}, async (data) => {
                setIsLoadingOrbit(false);
                dispatch(setDiagnosticList([]));
                dispatch(setDiagnosticFilterList([]));
                dispatch(setOrbitList(data));
            });
            await dispatch(orb);
        }

        if ((user.roles[0] === ORGANIZATION_ADMIN ||
            user.roles[0] === SYS_ADMIN ||
            user.roles[0] === DEPARTMENT_ADMIN) && (defaultOrbit && defaultOrbit?.length > 0)) {

            if (defaultOrbit && defaultOrbit.length > 0) {
                const diagFilterList = await diagnosesList({orbitId: defaultOrbit}, async (data) => {
                    dispatch(setDiagnosticFilterList(data));

                    if (!displayTiles) {
                        if (data.length === 0 || !Array.isArray(data)) {
                            dispatch(setDisplayTiles(true));
                            dispatch(setDisplayDiagnostics(false));
                            dispatch(setInitialTopNavDisplay(true));
                        } else {
                            dispatch(setDisplayTiles(false));
                            dispatch(setDisplayDiagnostics(true));
                        }
                    }
                });
                await dispatch(diagFilterList);
            }
        }


        if ((defaultOrbit && defaultOrbit.length > 0) || user?.defaultOrbit) {
            setIsLoading(true);
            const ca = await orbitClusterTileList({id: defaultOrbit && defaultOrbit.length > 0 ? defaultOrbit : user?.defaultOrbit}, HOME, async (data) => {
                setIsLoading(false);

                dispatch(setHome(data));
                const avatar = await userProfile({}, (data) => {
                    setProfileUrl(data.imageUrl);
                });
                await dispatch(avatar);
            });
            await dispatch(ca);
        }
    }

    useEffect(async () => {
        console.log('Page properties and attributes');
        await setupPagePropertiesAndAttributes();

        //get default orbit for logged in user
    }, [defaultOrbit]);

    useEffect(async () => {
        if (refreshTileAttributeList) {
            await setupPagePropertiesAndAttributes();
            setRefreshTileAttributeList(false);
        }
    }, [refreshTileAttributeList])

    useEffect(async () => {
        if ((user.roles[0] === ORGANIZATION_ADMIN ||
            user.roles[0] === SYS_ADMIN) && diagnosticFilterList && diagnosticFilterList.length > 0) {

            if (!diagnosticId || diagnosticId.length === 0) {
                dispatch(setDisplayTiles(false));

                if (!initialTopNavDisplay) {
                    dispatch(setDisplayDiagnostics(true));
                }
            }

            const dList = await diagList({filteredList: diagnosticFilterList}, async (data) => {
                dispatch(setDiagnosticList(data));

                if (initialTopNavDisplay) {
                    //if (!diagnosticId || diagnosticId.length === 0) {
                     //   dispatch(setDiagnosticId([data[0].diagnosisId]));
                    //}

                    dispatch(setDisplayDiagnostics(false));
                }
            });

            await dispatch(dList);
        }
    }, [diagnosticFilterList])

    useEffect(async () => {
        if (user?.roles[0] === REGULAR_USER && !isRegularUserSettingsComplete) {
            setIsRegularUserSettingsComplete(true);
            dispatch(setDisplayTiles(true));
            dispatch(setDisplayDiagnostics(false));
            setHasLoadedTiles(true);
        }
    })

    let displayCluster = false;
    if ((defaultOrbit && defaultOrbit.length > 0 || ((user?.roles[0] === REGULAR_USER || user?.roles[0] === DEPARTMENT_ADMIN || user?.roles[0] === ORBIT_ADMIN) && user?.defaultOrbit && user?.defaultOrbit.length > 0)) &&
        tileAttributes && tileAttributes?.length > 0)
        displayCluster = true;
    else
        displayCluster = false;

    if (user?.roles[0] === ORGANIZATION_ADMIN ||
    user?.roles[0] === SYS_ADMIN ||
    user?.roles[0] === DEPARTMENT_ADMIN)
        selectedDiagnosisId = Array.isArray(diagnosticId) && diagnosticId.length > 0 ? diagnosticId : diagnosticId ? [diagnosticId] : user?.dIdList ? user?.dIdList : null;
    else
        selectedDiagnosisId = user?.dIdList;

    let termsConditionsShowHide = ((user?.roles[0] !== ORGANIZATION_ADMIN && user?.roles[0] !== SYS_ADMIN && user?.roles[0] !== DEPARTMENT_ADMIN) && (user?.isTemporaryOrbitAccessUser === 0 || user?.isTemporaryOrbitAccessUser === null || user?.isTemporaryOrbitAccessUser === undefined) && !termsConditionsCompleted);

    return (
        <div className="w-100 authTrue">
            <div className="w-100">
                {termsConditionsShowHide &&
                <div className="termsContainer">
                    <div className="termsOverlay">
                        <div className="w-100">
                            <Container>
                                <div className="termsContent">
                                    <h1 className="text-dark">Welcome!</h1>
                                    <h2 className="text-dark">Since this is your first visit, please agree to the terms and conditions.</h2>
                                    <div className="terms">
                                        <p>This {currentUser()?.orbitNameForTermsConditions} is for your information and education only. Using this program does not take the place of conversations between you and your healthcare provider. This program gives general information about how some health problems may be treated. This information is extra education in addition to your appointments with your healthcare provider. This {currentUser()?.orbitNameForTermsConditions} is meant to educate you about medical conditions and treatments, but it does not cover everything. No guarantees or warranties are made about the treatment. This {currentUser()?.orbitNameForTermsConditions} is not meant to give exact medical advice to anyone. And it does not replace the informed consent process you will go through with your healthcare provider. Please talk with your healthcare provider about any questions or concerns you have about the information in this program.</p>
                                        <p>This CareOrbit&reg; program is for your information and education only. Using this program does not take the place of conversations between you and your healthcare provider. This program gives general information about how some health problems may be treated. This information is extra education in addition to your appointments with your healthcare provider. This CareOrbit program is meant to educate you about medical conditions and/or surgical procedures, but it does not cover everything. This program may go over the most common possible risks and complications of medical treatments or surgical procedures. But there may be other problems, known and unknown, that can happen after treatment or surgery. No guarantees or warranties are made about the treatment or surgery itself. This CareOrbit program is not meant to give exact medical or surgical advice to anyone. And it does not replace the informed consent process you will go through with your healthcare provider. Please talk with your healthcare provider about any questions or concerns you have about the information in this program. Also talk with your healthcare provider in person about what kinds of problems you may have if you do not have this treatment.</p>
                                        <p>Additional terms and conditions covering your use of the CareOrbit program are located at <Link target="_blank" to="terms-of-use">Terms Of Use</Link>, which are incorporated herein by reference.</p>
                                        <p>By clicking the box below, you acknowledge and accept the terms outlined above.</p>



                                    </div>
                                    <FormGroup>
                                        <FormControlLabel className="text-dark" control={<Checkbox/>} label=" I agree to the Terms and Conditions."
                                                          onChange={changeTermsConditions}/>
                                    </FormGroup>
                                </div>
                            </Container>
                        </div>
                    </div>
                </div>
                }
                {!termsConditionsShowHide &&
                <Container>
                    {(!initialTopNavDisplay && !displayTiles && !displayDiagnostics) && (orbitList && orbitList?.length > 0) && (
                        <SelectList list={orbitList} setSelectedValue={setValue}
                                    labels={{text: 'orbitName', value: 'orbitId'}}
                                    heading="Select an orbit to view:"/>)}
                    {!initialTopNavDisplay && displayDiagnostics && (diagnosticList && diagnosticList?.length > 0) && (
                        <SelectList list={diagnosticList} setSelectedValue={setDiagnosticValue}
                                    labels={{text: 'name', value: 'diagnosisId'}}
                                    heading="Select a diagnosis to view:"/>)}
                    {((displayTiles || !isLoading) && !displayDiagnostics &&
                    displayCluster ?
                        <Cluster
                            clusterType={clusterType}
                            useFlexContainer={useFlexContainer}
                            tileAttributes={tileAttributes}
                            profileUrl={profileUrl}
                            dIdList={selectedDiagnosisId}
                            clusterCssArray={clusterCssArray}
                            tileClickable={!user?.isTemporaryOrbitAccessUser}
                            key="homeCluster"
                        /> : (!isLoadingOrbit && !(orbitList && orbitList?.length > 0) ?
                            <div className="notFoundContainer" key="notFoundContainer">
                                <h1>
                                    The assigned orbit is under construction and will be released as soon as possible.
                                    Please feel free to try again later as
                                    we will continue to add valuable information.
                                </h1>
                            </div> : ''))}
                </Container>
                }
            </div>
        </div>
    )
};

export default Home;
