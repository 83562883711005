import React from "react";
import Container from "react-bootstrap/Container";
import {Nav} from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter_6_1_long_covid = () => {
    const opts = {
        height: "390",
        width: "640",
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100 legosNewBuild livingWellCenterHeader">
                <section className="mb-0">
                    {/* L6 Background image header bar */}
                    <div className="lego l006 legoMargin4">
                        <div
                            className="backgroundImage"
                            style={{
                                backgroundImage:
                                    "url(" +
                                    "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/90f39272d576e590cf2f6c8d6e445e5c.jpeg" +
                                    ")",
                            }}
                        >
                            <div
                                className="transparentBarTypeContainer"
                                style={{
                                    backgroundColor: "#75B8E4",
                                }}
                            >
                                &nbsp;&nbsp;
                            </div>
                            <h2 className="l6SubheadWithBackground">Long COVID-19 Program</h2>
                        </div>
                    </div>
                    {/* End L6 Background image header bar */}
                </section>
                <Container>
                    <div className="w-100">
                        <div className="col-12 m-auto">
                            <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                                <section>
                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 legoMargin3">
                                        <p>
                                            The WashU Living Well Center has developed a short-term
                                            supportive program to provide lifestyle medicine
                                            interventions for patients who have been diagnosed with
                                            Long COVID by Washington University’s{" "}
                                            <a
                                                className="blueLinkfont22"
                                                href="https://physicians.wustl.edu/specialties/infectious-diseases/post-covid-19-clinic/"
                                                rel="noreferrer noopener"
                                                target="_blank"
                                            >
                                                Post COVID-19 Clinic
                                            </a>
                                            .
                                        </p>
                                        <p>
                                            Washington University’s Post COVID-19 Clinic supports and
                                            treats patients who have continued symptoms or
                                            complications after COVID-19 illness, also called Long
                                            COVID or post-acute sequelae of COVID (PASC). This clinic
                                            is located within Washington University’s Infectious
                                            Disease Clinic.{" "}
                                        </p>
                                        <p>
                                            Long-term symptoms of Long COVID issues can include
                                            fatigue, "brain fog" or changes in thinking or
                                            concentration, shortness of breath or breathlessness,
                                            worsening of symptoms with increased activity, changes in
                                            sleep, changes in mood, and many others.
                                        </p>
                                        <p>
                                            There can also be lingering issues that affect the
                                            musculoskeletal system (the bones, joints and muscles of
                                            our body). To treat Long COVID, the Living Well Center
                                            provides opportunities for patients from the WashU Post
                                            COVID-19 Clinic to get lifestyle medicine support for
                                            their Long COVID symptoms.{" "}
                                        </p>
                                        <p className="legoMargin3">
                                            We’re also a proud partner of the{" "}
                                            <a
                                                className="blueLinkfont22"
                                                href="https://stlouisihn.org/long-covid-care/"
                                                rel="noreferrer noopener"
                                                target="_blank"
                                            >
                                                St. Louis Long COVID Initiative
                                            </a>
                                            . This Initiative is a newly developed, grant-funded
                                            program that provides Long COVID patients in St. Louis and
                                            rural Missouri and Illinois with resources, support and
                                            improved access to care.
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}
                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin2">
                                        <h2 className="subHeadAllCaps">PROGRAM DETAILS</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}
                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 legoMargin3">
                                        <p>
                                            The WashU Living Well Center identifies and addresses
                                            lifestyle strategies with a goal of managing and improving
                                            symptoms of Long COVID.
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}
                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 legoMargin2">
                                        <p>
                      <span className="boldLeadInNewUI">
                        The Living Well Center uses lifestyle changes to help
                        patients address:
                      </span>{" "}
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}
                                    {/* L53 Bullet List */}
                                    <div className="lego l053 legoMargin5">
                                        <ul className="w-100">
                                            <li className="w-100">Fatigue</li>
                                            <li className="w-100">Brain fog</li>
                                            <li className="w-100">
                                                Stress and mood disorders such as anxiety and depression
                                            </li>
                                            <li className="w-100">Sleep problems</li>
                                            <li className="w-100">Pain and deconditioning</li>
                                            <li className="w-100">
                                                Co-existing chronic medical conditions (such as high
                                                blood pressure or diabetes)
                                            </li>
                                        </ul>
                                    </div>
                                    {/* End L53 Bullet List */}
                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 legoMargin2">
                                        <p>
                      <span className="paragraph22px">
                        Care plans and treatment may include:
                      </span>{" "}
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}
                                    {/* L53 Bullet List */}
                                    <div className="lego l053 legoMargin3">
                                        <ul className="w-100">
                                            <li className="w-100 legoMargin1">
                                                <a
                                                    className="blueLinkfont22"
                                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8431/Services/Living-Well-Center/Shared-Medical-Appointments.aspx"
                                                    rel="noreferrer noopener"
                                                    target="_blank"
                                                >
                                                    Shared medical appointments
                                                </a>
                                            </li>
                                            <li className="w-100 legoMargin1">
                                                <a
                                                    className="blueLinkfont22"
                                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8358/Services/Living-Well-Center/Nutritional-Counseling.aspx"
                                                    rel="noreferrer noopener"
                                                    target="_blank"
                                                >
                                                    Nutritional counseling
                                                </a>
                                            </li>
                                            <li className="w-100 legoMargin1">
                                                <a
                                                    className="blueLinkfont22"
                                                    href="https://www.ortho.wustl.edu/content/Patient-Care/7140/Services/Living-Well-Center.aspx"
                                                    rel="noreferrer noopener"
                                                    target="_blank"
                                                >
                                                    Behavioral health counseling{" "}
                                                </a>
                                            </li>
                                            <li className="w-100 legoMargin1">
                                                Sleep hygiene counseling
                                            </li>
                                            <li className="w-100 legoMargin1">Physical therapy</li>
                                            <li className="w-100 legoMargin1">
                                                Occupational therapy
                                            </li>
                                            <li className="w-100 legoMargin1">Speech therapy</li>
                                            <li className="w-100 legoMargin1">
                                                Musculoskeletal care
                                            </li>
                                            <li className="w-100 legoMargin1">
                                                Pacing and energy management
                                            </li>

                                            <li className="w-100 legoMargin1">
                                                <a
                                                    className="blueLinkfont22"
                                                    href="https://www.ortho.wustl.edu/content/Patient-Care/6940/Services/Living-Well-Center/Smoking-Cessation.aspx"
                                                    rel="noreferrer noopener"
                                                    target="_blank"
                                                    style={{textDecoration: "underline"}}
                                                >
                                                    Smoking cessation
                                                </a>
                                            </li>
                                            <li className="w-100 legoMargin1">
                                                For select painful musculoskeletal conditions, treatment
                                                may also include:
                                                <br/>
                                                - Medical massage*
                                                <br/>-{" "}
                                                <a
                                                    className="blueLinkfont22"
                                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8326/Services/Living-Well-Center/Acupuncture.aspx"
                                                    rel="noreferrer noopener"
                                                    target="_blank"
                                                    style={{textDecoration: "underline"}}
                                                >
                                                    Acupuncture*
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* End L53 Bullet List */}
                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 legoMargin5">
                                        <p>
                      <span className="paragraph18">
                        *These services are not typically covered by insurance.
                        Because coverage varies, please check with
                        your insurance carrier to verify coverage.
                      </span>{" "}
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}
                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 legoMargin5">
                                        <p>
                      <span className="boldLeadInNewUI">
                        Long COVID Shared Medical Appointments 
                      </span>{" "}
                                        </p>
                                        <p>
                                            Shared medical appointments (SMAs) are medical
                                            office visits led by a medical provider and conducted in a
                                            group setting. These visits function to manage symptoms
                                            or conditions among multiple people with similar goals.
                                            The Long COVID shared medical appointments are group
                                            visits with other people who have Long COVID.
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 legoMargin5">
                                        <p>
                      <span className="boldLeadInNewUI">
                        Upcoming SMA Session:
                        <br/>
                        Physical and Cognitive Therapies for Long COVID Shared
                        Medical Appointments 
                      </span>{" "}
                                        </p>
                                        <p>
                                            This session is designed to address physical activity,
                                            self-care and communication challenges faced by patients
                                            with Long COVID. Each session will involve a physical,
                                            occupational and/or speech therapist to help patients
                                            effectively manage their symptoms.
                                        </p>
                                        <p>
                                            This session consists of six weekly one-hour classes that
                                            can be attended virtually or in-person. It is covered by
                                            Missouri and Illinois Medicaid.{" "}
                                            <a
                                                className="blueLinkfont22"
                                                href="https://www.ortho.wustl.edu/Calendar.aspx"
                                                rel="noreferrer noopener"
                                                target="_blank"
                                                style={{textDecoration: "underline"}}
                                            >
                                                View our events calendar for session dates
                                            </a>
                                            or call <span className="phoneLink">314-514-3565 </span>
                                            for more information.
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 legoMargin5">
                                        <p>
                      <span className="boldLeadInNewUI">
                        How do I sign up? 
                      </span>
                                        </p>
                                        <p>
                                            A referral is required from the WU CARE clinic or from a
                                            primary care provider with a diagnosis of Long COVID.{" "}Call {" "}
                                            <span className="phoneLink">314-514-3565 </span>
                                            for more information.
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin5">
                                        <h2 className="subHeadAllCaps">ADDITIONAL PRINTABLE RESOURCES</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin3">
                                        <h2 className="subHeadAllCaps">Therapies</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L99 Resource and Description New */}
                                    <div className="lego l099 legoMargin4">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/BreathingExercises.pdf">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/97e13bccf145746c37685e1369570c59.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p>Learn more about Breathing Exercises which can help your lungs
                                                    recover after COVID-19, including restoring diaphragm function,
                                                    increasing lung capacity, and helping you deal with feelings of
                                                    anxiety and stress. </p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/BreathingExercises.pdf">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99 Resource and Description New */}

                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                                    <div className="lego l099C legoMargin2">
                                        <div className="dualLegoContainer">

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Beginner+Program.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/0457d7d1fd6d89d74f537c53caa381e0.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p className="descriptionHeader">Beginner Level 1 exercises</p>
                                                        <p>Sample Aerobic exercises from beginner to advanced.</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Beginner+Program.pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End L99B Resource and Description Image Top and Centered New */}

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Beginner+level+2.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/625f9c459d0a5cce1c3321f6f21c29ac.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p className="descriptionHeader">Beginner Level 2 exercises</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Beginner+level+2.pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                                    <div className="lego l099C legoMargin2">
                                        <div className="dualLegoContainer">

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Intermediate.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/a1e02e96cceba85bbd1d75f3e715892e.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p className="descriptionHeader">Intermediate exercises</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Intermediate.pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End L99B Resource and Description Image Top and Centered New */}

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Advanced+Program.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/31472d67222306a1d00b80a43f14bff1.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p className="descriptionHeader">Advanced exercises</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Advanced+Program.pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                                    <div className="lego l099C legoMargin5">
                                        <div className="dualLegoContainer">

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Fatigue-and-PESE.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/6ad713260c54430ef847ec4f40f03175.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p>Learn more about Fatigue as you resume everyday activities
                                                            conservatively, at an appropriate pace that is safe and
                                                            manageable.</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Fatigue-and-PESE.pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End L99B Resource and Description Image Top and Centered New */}

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Pacing.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/d5c2652e5b279fc1449e7d5cbc7247fa.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p>Learn more about Pacing as a self-management strategy during
                                                            activity to avoid post-exertional symptom exacerbation
                                                            (PESE). Click image or this link to view. </p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Pacing.pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin5">
                                        <h2 className="subHeadAllCaps">Community Resources</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L99 Resource and Description New */}
                                    <div className="lego l099 legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/StL+Long+COVID+Initiative_General+Info+Corrected+Version.pdf">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7156acf53f48f983cae7800661fcf177.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p>Learn more about the St. Louis Long COVID Initiative, a community
                                                    partnership of regional health organizations who support the needs
                                                    of all people in Missouri, Southern Illinois and the surrounding
                                                    region.</p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/StL+Long+COVID+Initiative_General+Info+Corrected+Version.pdf">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99 Resource and Description New */}

                                    {/* L99 Resource and Description New */}
                                    <div className="lego l099 legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Peer+Support+Group_StL+Long+COVID.pdf">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/90d36fff0ec8c49c9ac79fadefe41292.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p>Get details on the Long COVID Peer Support Group offered by the St.
                                                    Louis Long COVID Initiative.</p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Peer+Support+Group_StL+Long+COVID.pdf">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99 Resource and Description New */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin3">
                                        <h2 className="subHeadAllCaps">Additional Tools</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L99 Resource and Description New */}
                                    <div className="lego l099 legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Visible+and+Health+Storylines+Apps.pdf">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/c84e9261fa1e90e0e83298c4cf630a8d.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p>Learn more about apps designed to help track and monitor your health
                                                    progress.</p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Visible+and+Health+Storylines+Apps.pdf">this
                                                    link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99 Resource and Description New */}

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p>
                                                <a
                                                    className="specialUseBackToTop"
                                                    onClick={() =>
                                                        window.scrollTo({top: 0, behavior: "smooth"})
                                                    }
                                                >
                                                    BACK TO TOP{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default LivingWellCenter_6_1_long_covid;
