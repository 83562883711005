import React from "react";

function L99AComponentCode() {
  return (
    <div>
      {/* L99A App Icon and Description New */}
      <div className="lego l099A legoMargin5">
        <div className="legoContainer">
          <div className="imgContainer">
            <a href="">
              <img
                  className=""
                  src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/a5287dc69aed23322d9756fa8db32851.jpeg"
              />
            </a>
          </div>
          <div className="contentContainer">
            <p className="descriptionHeader">Insomnia Coach</p>
            <p>A guided, weekly training plan to help you track and improve your
              sleep, which includes a sleep coach with personal feedback about
              your sleep and fun sleep tips and an interactive sleep diary to help
              you keep track of daily changes in your sleep.</p>
            <p>Click image or <a
                href="">this
              link</a> to view.</p>
          </div>
        </div>
      </div>
      {/* End L99A App Icon and Description Newe */}
    </div>
  );
}

export default L99AComponentCode;
