import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter6_2 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/0d445c7f791eb836ce7a12e72e3003b1.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#FA4E36",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">
                Weight Loss & Nutrition
              </h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L12A Head, Lead In Photo Larger with body copy */}
                  <div className="lego l012A">
                    <div className="contentContainer">
                      <div
                        className="imgContainer"
                        style={{ minWidth: "500px" }}
                      >
                        <img
                          className=""
                          alt="Adults of Mulitple Ethnicities doing Tai Chi"
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/09f122dce6580fd5676488e38a3cc11b.jpeg"
                        />
                      </div>
                      <div className="copyContainer">
                        {/* L14 Paragraph */}
                        <div className="lego l00paragraph legoMargin3">
                          <p className="legoMargin2">
                            <span className="bold italic">
                              If you need to lose weight in order to improve
                              your health, the Washington University Living Well
                              Center® can help. 
                            </span>
                          </p>
                          <p className="legoMargin2">
                            We offer dietitian-led, weight loss shared medical
                            appointments expertly designed to help you drop
                            excess weight using the latest evidence-based
                            approach that focuses on how to expand your healthy
                            food intake, and provide education as to what foods
                            to limit and why. The program includes the support
                            and resources you need to meet your goal.
                          </p>
                          <p className="legoMargin2">
                            {" "}
                            By using a whole-food, plant-predominant lifestyle,
                            you will reduce your weight and the risk of chronic
                            diseases (such as diabetes and hypertension)
                            associated with excess weight and obesity.{" "}
                          </p>
                          <p className="legoMargin2">
                            Participants receive guidance from a dietitian who
                            specializes in plant-based diet and healthy
                            lifestyles, as well as social support from peers in
                            a group setting.
                          </p>{" "}
                          <p className="legoMargin2">
                            The education you receive will go beyond just food
                            and will include comprehensive lifestyle medicine –
                            a recipe for long-term success. We give you tools to
                            meet your weight loss goals (our methods are proven
                            to result in greater weight loss) and maintain your
                            success long-term. Four biweekly group sessions are
                            delivered virtually and in-person, increasing safe
                            access to patients everywhere.
                          </p>
                          <p>
                            Click to{" "}
                            <a
                              className="blueLinkfont18"
                              href="https://www.ortho.wustl.edu/content/Patient-Care/8357/Services/Living-Well-Center/Living-Well-Center-Weight-Loss-Program.aspx"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Learn More
                            </a>
                          </p>
                        </div>
                        {/* End L14 Paragraph */}
                      </div>
                    </div>
                  </div>
                  {/* End L12A Head, Lead In Photo Larger with body copy */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin1">
                    <h2 className="subHeadAllCaps">LIFESTYLE NUTRITION</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L99B Resource and Description Image Top and Centered New */}
                  <div className="lego l099B">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Nutrition+23_1.pdf">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LifeNutritionGudie.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p>
                          <span className="bold">
                            {" "}
                            Lifestyle Nutrition Guide
                          </span>
                        </p>
                        <p>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Nutrition+23_1.pdf"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99B Resource and Description Image Top and Centered New */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l00paragraph2 legoMargin4">
                    <p style={{ textAlign: "center" }}>
                      <span
                        className="paragraph22px"
                        style={{ fontStyle: "normal" }}
                      >
                        {" "}
                        Comparing costs of different diets.
                      </span>
                      <br /> <br />
                      Get tips and comparisons of common diets to help you eat
                      healthier within your budget. 
                    </p>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L99B Resource and Description Image Top and Centered New */}
                  <div className="lego l099B">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/WFPB+Cost+Analz+24.pdf">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ComparingCost_LR.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p>
                          <span className="bold"> Cost Comparison Guide </span>
                        </p>
                        <p>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/WFPB+Cost+Analz+24.pdf"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99B Resource and Description Image Top and Centered New */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">FOOD FACTS</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l00paragraph2 legoMargin4">
                    <p style={{ textAlign: "center" }}>
                      <span
                        className="paragraph22px"
                        style={{ fontStyle: "normal" }}
                      >
                        {" "}
                        Understanding “Healthy Plates” 
                      </span>
                    </p>
                    <p style={{ textAlign: "center" }}>
                      Learn more about what kinds of food should be on your
                      healthier eating planwhen you fill your plate for every
                      meal.
                    </p>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                  <div className="lego l099C legoMargin4">
                    <div className="dualLegoContainer">
                      {/* L99B Resource and Description Image Top and Centered New */}
                      <div className="lego l099B">
                        <div className="legoContainer">
                          <div className="imgContainer">
                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ACLM+Plate.pdf">
                              <img
                                className=""
                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ACLM+Plate_LR.png"
                              />
                            </a>
                          </div>
                          <div className="contentContainer">
                            <p>
                              Get details of a{" "}
                              <span className="bold">
                                Whole Food, Plant-Based Plate{" "}
                              </span>
                            </p>
                            <p>
                              Click image or{" "}
                              <a target="_blank"
                                className="blueLinkfont"
                                href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ACLM+Plate.pdf"
                              >
                                this link
                              </a>{" "}
                              to view.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* End L99B Resource and Description Image Top and Centered New */}

                      {/* L99B Resource and Description Image Top and Centered New */}
                      <div className="lego l099B">
                        <div className="legoContainer">
                          <div className="imgContainer">
                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Canada+Plate.pdf">
                              <img
                                className=""
                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Canada+Plate_LR.png"
                              />
                            </a>
                          </div>
                          <div className="contentContainer">
                            <p>
                              More details on the{" "}
                              <span className="bold">
                                ’Canada Plate’ approach
                              </span>
                            </p>
                            <p>
                              Click image or{" "}
                              <a target="_blank"
                                className="blueLinkfont"
                                href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Canada+Plate.pdf"
                              >
                                this link
                              </a>{" "}
                              to view.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l00paragraph2 legoMargin4">
                    <p style={{ textAlign: "center" }}>
                      <span
                        className="paragraph22px"
                        style={{ fontStyle: "normal" }}
                      >
                        Helpful Details on Healthier Eating 
                      </span>
                    </p>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                  <div className="lego l099C legoMargin3">
                    <div className="dualLegoContainer">
                      {/* L99B Resource and Description Image Top and Centered New */}
                      <div className="lego l099B legoMargin3">
                        <div className="legoContainer">
                          <div className="imgContainer">
                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Calorie-Density.pdf">
                              <img
                                className=""
                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Calorie-Density_LR.png"
                              />
                            </a>
                          </div>
                          <div className="contentContainer">
                            <p>
                              Learn more about{" "}
                              <span className="bold">Calorie Density </span>
                              of foods
                            </p>
                            <p>
                              Click image or{" "}
                              <a target="_blank"
                                className="blueLinkfont"
                                href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Calorie-Density.pdf"
                              >
                                this link
                              </a>{" "}
                              to view.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* End L99B Resource and Description Image Top and Centered New */}

                      {/* L99B Resource and Description Image Top and Centered New */}
                      <div className="lego l099B legoMargin3">
                        <div className="legoContainer">
                          <div className="imgContainer">
                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Superfood+List+7_13_22.pdf">
                              <img
                                className=""
                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Superfood+List+7_13_22_LR.png"
                              />
                            </a>
                          </div>
                          <div className="contentContainer">
                            <p>
                              List of <span className="bold">Superfoods</span>
                            </p>
                            <p>
                              Click image or{" "}
                              <a target="_blank"
                                className="blueLinkfont"
                                href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Superfood+List+7_13_22.pdf"
                              >
                                this link
                              </a>{" "}
                              to view.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                  {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                  <div className="lego l099C legoMargin3">
                    <div className="dualLegoContainer">
                      {/* L99B Resource and Description Image Top and Centered New */}
                      <div className="lego l099B">
                        <div className="legoContainer">
                          <div className="imgContainer">
                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Plant+vs.+Animal+Protein.pdf">
                              <img
                                className=""
                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Plant+vs.+Animal+Protein_LR.png"
                              />
                            </a>
                          </div>
                          <div className="contentContainer">
                            <p>
                              Comparison of{" "}
                              <span className="bold">
                                Plant vs. Animal Protein Sources{" "}
                              </span>
                            </p>
                            <p>
                              Click image or{" "}
                              <a target="_blank"
                                className="blueLinkfont"
                                href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Plant+vs.+Animal+Protein.pdf"
                              >
                                this link
                              </a>{" "}
                              to view.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* End L99B Resource and Description Image Top and Centered New */}

                      {/* L99B Resource and Description Image Top and Centered New */}
                      <div className="lego l099B">
                        <div className="legoContainer">
                          <div className="imgContainer">
                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Vegan+iron+sources.pdf">
                              <img
                                className=""
                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Vegan+iron+sources_LR.png"
                              />
                            </a>
                          </div>
                          <div className="contentContainer">
                            <p>
                              Good sources of{" "}
                              <span className="bold">Iron on a Vegan Diet</span>
                            </p>
                            <p>
                              Click image or{" "}
                              <a target="_blank"
                                className="blueLinkfont"
                                href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Vegan+iron+sources.pdf"
                              >
                                this link
                              </a>{" "}
                              to view.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                  {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                  <div className="lego l099C legoMargin3">
                    <div className="dualLegoContainer">
                      {/* L99B Resource and Description Image Top and Centered New */}
                      <div className="lego l099B ">
                        <div className="legoContainer">
                          <div className="imgContainer">
                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Calcium.pdf">
                              <img
                                className=""
                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Calcium.png"
                              />
                            </a>
                          </div>
                          <div className="contentContainer">
                            <p>
                              List of good sources of{" "}
                              <span className="bold">Calcium </span>
                            </p>
                            <p>
                              Click image or{" "}
                              <a target="_blank"
                                className="blueLinkfont"
                                href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Calcium.pdf"
                              >
                                this link
                              </a>{" "}
                              to view.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* End L99B Resource and Description Image Top and Centered New */}

                      {/* L99B Resource and Description Image Top and Centered New */}
                      <div className="lego l099B ">
                        <div className="legoContainer">
                          <div className="imgContainer">
                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/How+to+make+a+nourish+bowl.pdf">
                              <img
                                className=""
                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/How+to+make+a+nourish+bowl_LR.png"
                              />
                            </a>
                          </div>
                          <div className="contentContainer">
                            <p>
                              How to make a{" "}
                              <span className="bold">‘Nourish Bowl’</span>
                            </p>
                            <p>
                              Click image or{" "}
                              <a target="_blank"
                                className="blueLinkfont"
                                href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/How+to+make+a+nourish+bowl.pdf"
                              >
                                this link
                              </a>{" "}
                              to view.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                  {/* L99B Resource and Description Image Top and Centered New */}
                  <div className="lego l099B">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Plant+based+snacks.pdf">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Plant+based+snacks.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p>
                          Simple{" "}
                          <span className="bold">
                            {" "}
                            Plant-based Snacks and Recipes
                          </span>
                        </p>
                        <p>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Plant+based+snacks.pdf"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99B Resource and Description Image Top and Centered New */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin1">
                    <h2 className="subHeadAllCaps">
                      UNDERSTANDING NUTRITION MYTHS
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l00paragraph2 legoMargin4">
                    <p style={{ textAlign: "center" }}>
                      When planning and following a healthier diet, not all
                      information you may hear or see is accurate. Be sure you
                      know the facts!
                    </p>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L99B Resource and Description Image Top and Centered New */}
                  <div className="lego l099B">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Nutrition+Myth+2023.pdf">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Nutrition+Myth+2023_LR.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p>
                          Get the facts on{" "}
                          <span className="bold"> Common Nutrition Myths</span>
                        </p>
                        <p>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Nutrition+Myth+2023.pdf"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99B Resource and Description Image Top and Centered New */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin1">
                    <h2 className="subHeadAllCaps">
                      KEEPING A FOOD AND BEVERAGE DIARY
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l00paragraph2 legoMargin4">
                    <p style={{ textAlign: "center" }}>
                      A food and beverage diary is an easy tool to help you and
                      your team know how much and how well you are eating. 
                    </p>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L99B Resource and Description Image Top and Centered New */}
                  <div className="lego l099B">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Food+and+beverage+diary.pdf">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Food+and+beverage+diary_LR.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p>
                          Print out your weekly{" "}
                          <span className="bold"> Food and Beverage Diary</span>
                        </p>
                        <p>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Food+and+beverage+diary.pdf"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99B Resource and Description Image Top and Centered New */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">FOOD AND NUTRITION APPS</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://www.pcrm.org/news/news-releases/21-day-vegan-kickstart-program-launches-new-website-and-phone-app">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/21DayVegan.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">
                          21-Day Vegan Kickstart
                        </p>
                        <p>
                          A free app designed to provide a 3-week plant-based
                          meal plan. Plans are designed for nutritional
                          completeness and are paired with recipe libraries and
                          nutritional guidance.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://www.pcrm.org/news/news-releases/21-day-vegan-kickstart-program-launches-new-website-and-phone-app"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  {/*<div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://getbitesnap.com/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Bitesnap.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">Bitesnap</p>
                        <p>
                          (Available on App Store and Google Play Store, free):
                          This simple app allows users to keep a photo diary of
                          the meals they consume throughout the day and
                          visualize the amount of food they are consuming. The
                          app also includes a calorie-counting feature.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://getbitesnap.com/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>*/}                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://get.gg/docs/CravingsDiary.pdf">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ClassPass_app_logo.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">Cravings Diary</p>
                        <p>
                          This template provides users with the opportunity to
                          pause when experiencing a craving, and to further
                          query the nature of the craving and their experience
                          of it. The questions provided on the document are
                          applicable to cravings both related and unrelated to
                          food.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://get.gg/docs/CravingsDiary.pdf"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://nutritionfacts.org/daily-dozen/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/DrGregersDailyDozen.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">
                          Dr. Greger’s Daily Dozen
                        </p>
                        <p>
                          Free, checklist-style app that inspires you to include
                          the healthiest foods into your daily meals.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://nutritionfacts.org/daily-dozen/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://goeatrightnow.com/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/EatingNow.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">Eat Right Now</p>
                        <p>
                          (Available on App Store and Google Play Store): This
                          mobile app provides users with 4 free modules that are
                          tailored to their goals around food. The app prompts
                          users at intervals to assess their own emotional and
                          physical state and, in so doing, promotes the
                          development of mindfulness. A monthly subscription is
                          required after completion of the first 4 modules
                          ($24.99/month).
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://goeatrightnow.com/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://shop.forksoverknives.com/pages/save-50-now">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ForksMealPlanner.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">Forks Meal Planner</p>
                        <p>
                          Accessible via free online trial or through the paid
                          mobile app. Provides plant-based, oil-free recipes for
                          breakfast, lunch, dinner, and a snack. Weekly meal
                          plans include a grocery list, meal prep, and
                          members-only community.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://shop.forksoverknives.com/pages/save-50-now"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://www.fooducate.com/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Fooducate.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">Fooducate</p>
                        <p>
                          Improve your health by creating good habits & eating
                          more mindfully.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://www.fooducate.com/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://www.happycow.net/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/HappyCow.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">Happy Cow</p>
                        <p>
                          The go-to app for finding plant-based food near you!
                          This app filters by “Vegan,” “Vegetarian,” and “Vegan
                          Options,” to help you find restaurants, cafes, and
                          grocery stores in 180+ countries. Simply search by
                          dropping a pin on the map or typing in the area you’re
                          visiting—Favorite lists and Travel lists are a great
                          feature for future use. Users can leave reviews with
                          pictures on each business’s page, making it easy to
                          find what you’re looking for.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://www.happycow.net/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://www.lighter.world/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/lighter.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">Lighter</p>
                        <p>
                          Provides meal plans customizable by personal health
                          goals, appetite, preparation time, and cooking skill.
                          Meals are paired with a grocery list. Free trial
                          available.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://www.lighter.world/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://www.myfitnesspal.com/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/MyFitnessPal.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">MyFitnessPal</p>
                        <p>
                          A free calorie-counting app that tracks
                          macronutrients, vitamins, fiber, and other minerals.
                          Users may also log their water consumption and
                          exercise with this app. Users can sync data from
                          MyFitnessPal with the FitBit, Garmin, and Apple Health
                          apps, and more!
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://www.myfitnesspal.com/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://www.mynetdiary.com/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/MyNetDiary.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">MyNetDiary</p>
                        <p>
                          A free weight and food tracking app which provides
                          daily and weekly analyses of users’ diets,
                          macronutrients, and micronutrients. A barcode scanner
                          and nutrition tips are provided. A public
                          discussion/support forum is also available for users.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://www.mynetdiary.com/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://www.noom.com/?route=_direct&utm_content=453318178305&utm_term=kwd-299095591416%7Cnoom">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/noom.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">Noom</p>
                        <p>
                          Psychology-based lifestyle change app for long-term
                          weight loss. This app is subscription-based and
                          creates a customized plan that includes mini-lessons
                          in psychology and behavior change. Food tracking
                          follows a green/yellow/red system, and allows for
                          dietary preferences. Includes a coach-moderated peer
                          support group. Free one-week trial available. Click
                          image or this link to view.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://www.noom.com/?route=_direct&utm_content=453318178305&utm_term=kwd-299095591416%7Cnoom"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://mealplanner.richroll.com">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/PlantPowerMealPlanner.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">
                          The Plantpower Meal Planner
                        </p>
                        <p>
                          Personalized meal planner based on goals, kitchen
                          skills, time to cook, and other preferences. Also
                          includes support from food coaches and cooking
                          technique videos. Click image or this link to view.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank" className="blueLinkfont" href="https://mealplanner.richroll.com">
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://www.innit.com/shopwell/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Shopwell.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">ShopWell</p>
                        <p>
                          Simplifies nutrition labels and helps you discover new
                          foods that fit your lifestyle.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://www.innit.com/shopwell/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  {/* L99A App Icon and Description New */}
                  <div className="lego l099A legoMargin5">
                    <div className="legoContainer">
                      <div className="imgContainer">
                        <a target="_blank" href="https://yuka.io/en/">
                          <img
                            className=""
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Yuka.png"
                          />
                        </a>
                      </div>
                      <div className="contentContainer">
                        <p className="descriptionHeader">Yuka app</p>
                        <p>
                          Bring this app to the grocery store to evaluate food
                          quality before you check-out! Features a barcode
                          scanner that provides you with an immediate grade:
                          from excellent to bad. This may be particularly
                          helpful for processed foods. Remember: the healthiest
                          foods don’t have barcodes!
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                          Click image or{" "}
                          <a target="_blank"
                            className="blueLinkfont"
                            href="https://yuka.io/en/"
                          >
                            this link
                          </a>{" "}
                          to view.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L99A App Icon and Description New */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter6_2;
