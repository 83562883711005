import React from "react";
import Container from "react-bootstrap/Container";
import {Nav} from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter_6_2c_cancer_survivorship = () => {
    const opts = {
        height: "390",
        width: "640",
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100 legosNewBuild livingWellCenterHeader">
                <section className="mb-0">
                    {/* L6 Background image header bar */}
                    <div className="lego l006 legoMargin4">
                        <div
                            className="backgroundImage"
                            style={{
                                backgroundImage:
                                    "url(" +
                                    "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/9774f2a269bc7ff0399095d941cc4013.jpeg" +
                                    ")",
                            }}
                        >
                            <div
                                className="transparentBarTypeContainer"
                                style={{
                                    backgroundColor: "#FA4E36",
                                }}
                            >
                                &nbsp;&nbsp;
                            </div>
                            <h2 className="l6SubheadWithBackground">Cancer Survivorship with the Living Well Center</h2>
                        </div>
                    </div>
                    {/* End L6 Background image header bar */}
                </section>
                <Container>
                    <div className="w-100">
                        <div className="col-12 m-auto">
                            <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                                <section>
                                    {/* L2 */}
                                    <div className="lego l002 legoMargin3">
                                        <h2 className="l2Headline">
                                            Being Active When You Have Cancer.
                                        </h2>
                                    </div>
                                    {/* End L2 */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin3">
                                        <p>Being active during and after a cancer diagnosis is an important way to
                                            reduce fatigue, increase strength and help ease depression. Regular physical
                                            activity, including aerobic activity and strength training, can help you get
                                            through your treatments and decrease treatment side effects. In addition,
                                            stretching can help restore flexibility in joints that have lost range of
                                            motion due to surgery or radiation.</p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                                    <div className="lego l099C legoMargin2">
                                        <div className="dualLegoContainer">

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Physical+Activity+Cancer+info+guide+(1).pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/a68b76776be2382c3b903b5804b31ac3.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p>Learn more about being active when you have cancer.</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Physical+Activity+Cancer+info+guide+(1).pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End L99B Resource and Description Image Top and Centered New */}

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ACSM-Roundtable-on-Cancer-and-Exercise+(1).pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7bbeeb3d7dc67d4f2faf0b90238c1214.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p>"Moving Through Cancer: Exercise for People Living With and
                                                            Moving Beyond Cancer"</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ACSM-Roundtable-on-Cancer-and-Exercise+(1).pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                                    <div className="lego l099C legoMargin2">
                                        <div className="dualLegoContainer">

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/exercise-guidelines-cancer-infographic+(1).pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/8252fa4b591b3c35a8db1387c01c8fde.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p>Get more details on the effects of exercise on health-related
                                                            outcomes in those with cancer</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/exercise-guidelines-cancer-infographic+(1).pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End L99B Resource and Description Image Top and Centered New */}

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B invisible">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Advanced+Program.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/31472d67222306a1d00b80a43f14bff1.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p className="descriptionHeader">Advanced exercises</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Long+Covid+Advanced+Program.pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin5">
                                        <h2 className="subHeadAllCaps">Sample Exercise Programs</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                                    <div className="lego l099C">
                                        <div className="dualLegoContainer">

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ONC+Strength+Beginner+(1).pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/a263cb993ae287e7c51f3584e41089fc.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p>Beginner Strength sample exercises</p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ONC+Strength+Beginner+(1).pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End L99B Resource and Description Image Top and Centered New */}

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ONC+Strength+intermediate+(1).pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/d1234d079b7cfaa8e6af0bbed6eb279c.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p>Intermediate Strength sample exercises </p>
                                                        <p>Click image or <a target="_blank"
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/ONC+Strength+intermediate+(1).pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                                    {/* L66 Divider Rule */}
                                    <div className="lego l066">
                                        <hr className="horizonalRule"/>
                                    </div>
                                    {/* End L66 Divider Rule */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin5">
                                        <h2 className="subHeadAllCaps">THE lIVESTRONG PROGRAM</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin3">
                                        <p>LIVESTRONG as the YMCA is an evidence-based program that helps adult cancer
                                            survivors reclaim their health and well-being following a cancer diagnosis.
                                            Participating YMCAs create a welcoming community in which survivors can
                                            improve their strength and physical fitness, diminish the severity of
                                            therapy side effects, develop supportive relationships, and improve their
                                            quality of life.</p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L99B Resource and Description Image Top and Centered New */}
                                    <div className="lego l099B legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Livestrong+(1).pdf">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/1676e2dd029f92c13a25036fb28eb2f4.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p>Learn more about LIVESTRONG as the YMCA.</p>
                                                <p>Click image or <a target="_blank"
                                                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Livestrong+(1).pdf">this
                                                    link</a> to view.</p>
                                                <p>Or, you can find more details of the Gateway Region YMCA LIVESTRONG
                                                    at:</p>
                                                <p><a target="_blank"
                                                    href="https://gwrymca.org/livestrong-ymca">https://gwrymca.org/livestrong-ymca</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99B Resource and Description Image Top and Centered New */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin3">
                                        <p>This 12-week program for adult cancer survivors meets twice a week. The YMCA
                                            wellness coaches are trained in the elements of cancer, post rehab exercise,
                                            and supportive cancer care. </p>
                                        <p>Thanks to the support of our Annual Campaign donors, LIVESTRONG at the YMCA
                                            is offered at no cost to cancer survivors and provides facility access to
                                            the survivor’s household. Space is limited for each session to allow for a
                                            low instructor-to-participant ratio. Survivors are limited to participating
                                            once, in order to accommodate others. </p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin3">
                                        <p className="boldLeadInNewUI"><strong>The Program is available at these locations:</strong></p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin3">
                                        <p>Carondelet Park Rec Complex: <a href="tel:3147689622">(314) 768-9622</a></p>
                                        <p>Chesterfield Family YMCA: <a href="tel:6365323100">(636) 532-3100</a></p>
                                        <p>Downtown Belleville Kern Center YMCA: <a href="tel:6182331243">(618) 233-1243</a></p>
                                        <p>East Belleville YMCA: <a href="tel:6182369983">(618) 236-9983</a></p>
                                        <p>Four Rivers Family YMCA: <a href="tel:6362395704">(636) 239-5704</a></p>
                                        <p>Jefferson County Family YMCA: <a href="tel:6369319622">(636) 931-9622</a></p>
                                        <p>Kirkwood Family YMCA: <a href="tel:3149659622">(314) 965-9622</a></p>
                                        <p>Mid-County Family YMCA: <a href="tel:3149629450">(314) 962-9450</a></p>
                                        <p>Monroe County YMCA - HTC Center: <a href="tel:6182819622">(618) 281-9622</a></p>
                                    </div>
                                    {/* End L14 Paragraph */}


                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p>
                                                <a
                                                    className="specialUseBackToTop"
                                                    onClick={() =>
                                                        window.scrollTo({top: 0, behavior: "smooth"})
                                                    }
                                                >
                                                    BACK TO TOP{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default LivingWellCenter_6_2c_cancer_survivorship;
