import React from "react";
import Container from "react-bootstrap/Container";
import {Nav} from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter6_5 = () => {
    const opts = {
        height: "390",
        width: "640",
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100 legosNewBuild livingWellCenterHeader">
                <section className="mb-0">
                    {/* L6 Background image header bar */}
                    <div className="lego l006 legoMargin5">
                        <div
                            className="backgroundImage"
                            style={{
                                backgroundImage:
                                    "url(" +
                                    "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/lwc_6.5_background.jpeg" +
                                    ")",
                            }}
                        >
                            <div
                                className="transparentBarTypeContainer"
                                style={{
                                    backgroundColor: "#FA4E36",
                                }}
                            >
                                &nbsp;&nbsp;
                            </div>
                            <h2 className="l6SubheadWithBackground">
                                Behavioral Health Counseling
                            </h2>
                        </div>
                    </div>
                    {/* End L6 Background image header bar */}
                </section>
                <Container>
                    <div className="w-100">
                        <div className="col-12 m-auto">
                            <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                                <section>
                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 ">
                                        <p>
                      <span className="paragraph600px">
                        The mind-body connection.{" "}
                      </span>{" "}
                                            It's a phrase that's generated quite the buzz in recent
                                            years, and for good reason. But what exactly is the
                                            mind-body connection, and what role does it play in the
                                            field of lifestyle medicine?
                                        </p>
                                        <p className="legoMargin2">
                                            Historically, medicine has focused on treating the
                                            physical symptoms of the body separate from emotional
                                            responses or experiences. Thanks to a vast amount of
                                            research that has been conducted on the subject, we now
                                            know there is an intricate connection between a person’s
                                            physical and emotional states.
                                        </p>
                                        <p className="legoMargin2">
                                            Thoughts, feelings and attitudes can positively or
                                            negatively affect the way our body functions. For example,
                                            if we experience chronic pain, there is a physical
                                            component of pain in the body. When that persists, a sense
                                            of hopelessness or depression may occur due to a lack of
                                            ability to engage in life.
                                        </p>
                                        <p>
                                            At the Living Well Center, our unique focus is on helping
                                            patients make positive changes to their lifestyle to
                                            improve mental and physical health at the same time. Some
                                            of areas we can help include the following.
                                        </p>
                                    </div>

                                    {/* End L14 Paragraph */}

                                    {/* L66 Divider Rule */}
                                    <div className="lego l066">
                                        <hr className="horizonalRule"/>
                                    </div>
                                    {/* End L66 Divider Rule */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin3">
                                        <h2 className="subHeadAllCaps">STRESS REDUCTION</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph2 ">
                                        <p className="legoMargin2">
                                            Stress is unavoidable. But how we think about it and how
                                            we react to it makes the difference in how it impacts our
                                            self-care and our health. While some medicine may help to
                                            manage stress, there are many things that you can do to
                                            help you manage stress and improve overall health.
                                        </p>
                                    </div>

                                    {/* End L14 Paragraph */}

                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                                    <div className="lego l099C">
                                        <div className="dualLegoContainer">
                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Stress+Mgmt+23.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/LM+Stress+Mgmt+23.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p className="descriptionHeader">
                                                            Lifestyle Stress Reduction Guide
                                                        </p>

                                                        <p>
                                                            Click image or{" "}
                                                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Stress+Mgmt+23.pdf">
                                                                this link
                                                            </a>{" "}
                                                            to view.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End L99B Resource and Description Image Top and Centered New */}

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Social+23.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/LM+Social+23.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p className="descriptionHeader">
                                                            Lifestyle Social Connection Guide
                                                        </p>
                                                        <p>
                                                            Click image or{" "}
                                                            <a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Social+23.pdf">
                                                                this link
                                                            </a>{" "}
                                                            to view.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}

                                    {/* L66 Divider Rule */}
                                    <div className="lego l066">
                                        <hr className="horizonalRule"/>
                                    </div>
                                    {/* End L66 Divider Rule */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin1">
                                        <h2 className="subHeadAllCaps">
                                            FREE MENTAL HEALTH AND MINDFULNESS APPS
                                        </h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}
                                    {/* L3 Subhead Upper and Lower */}
                                    <div className="lego l00paragraph2 legoMargin4">
                                        <p style={{textAlign: "center"}}>
                                            Check out these apps to see if they are right for you!
                                        </p>
                                    </div>
                                    {/* End L3 Subhead Upper and Lower */}

                                    {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://www.dareresponse.com/dareapp/">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Dare_app_logo.png"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Dare</p>
                                                <p>
                                                    Panic & Anxiety Relief is an evidence-based training
                                                    program to help people overcome anxiety, panic
                                                    attacks, worry, and insomnia. You can track your
                                                    progress daily with the mood journal.
                                                </p>
                                                <p style={{fontStyle: "italic"}}>
                                                    Click image or{" "}
                                                    <a target="_blank"
                                                        className="blueLinkfont"
                                                        href="https://www.dareresponse.com/dareapp/"
                                                    >
                                                        this link
                                                    </a>{" "}
                                                    to view.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description New */}

                                    {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://www.sparkapp.app/">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/SparkGratitude_app_logo.png"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">
                                                    Spark Gratitude Journal
                                                </p>

                                                <p>
                                                    Ignite the power of gratitude and positive thinking
                                                    with the Spark App. Seamlessly designed to infuse your
                                                    life with mindfulness, our app transforms daily tasks
                                                    into rewarding moments of self-discovery.
                                                </p>
                                                <p style={{fontStyle: "italic"}}>
                                                    Click image or{" "}
                                                    <a target="_blank"
                                                        className="blueLinkfont"
                                                        href="https://www.sparkapp.app/"
                                                    >
                                                        this link
                                                    </a>{" "}
                                                    to view.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description New */}

                                    {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://mobile.va.gov/app/mindfulness-coach">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/MindfulnessCoach_app_logo.png"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Mindfulness Coach</p>
                                                <p>
                                                    Mindfulness has been shown to be effective for
                                                    reducing stress, improving emotional balance,
                                                    increasing self-awareness, helping with anxiety and
                                                    depression, and coping more effectively with chronic
                                                    pain.
                                                </p>
                                                <p style={{fontStyle: "italic"}}>
                                                    Click image or{" "}
                                                    <a target="_blank"
                                                        className="blueLinkfont"
                                                        href="https://mobile.va.gov/app/mindfulness-coach"
                                                    >
                                                        this link
                                                    </a>{" "}
                                                    to view.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description New */}

                                    {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://balanceapp.com/">
                                                    <img
                                                        className="whiteIcon"
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Balance_app_logo.png"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">
                                                    Balance: Meditation & Sleep
                                                </p>
                                                <p>
                                                    Improve your stress, sleep, and more with the world’s
                                                    first personalized meditation program, now free for
                                                    your first year.
                                                </p>
                                                <p style={{fontStyle: "italic"}}>
                                                    Click image or
                                                    <a target="_blank"
                                                        className="blueLinkfont"
                                                        href="https://balanceapp.com/"
                                                    >
                                                        {" "}
                                                        this link
                                                    </a>{" "}
                                                    to view.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description New */}

                                    {/* L99A App Icon and Description New */}
                                    <div className="lego l099A legoMargin5">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a target="_blank" href="https://www.portal.hminnovations.org/launch">
                                                    <img
                                                        className="whiteIcon"
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/HealthyMinds_app_logo..jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">
                                                    Healthy Minds Program
                                                </p>
                                                <p>
                                                    Uses neuroscience, contemplative traditions, and
                                                    skill-based learning methods to help you develop the
                                                    skills for a healthy mind.
                                                </p>
                                                <p style={{fontStyle: "italic"}}>
                                                    Click image or
                                                    <a target="_blank"
                                                        className="blueLinkfont"
                                                        href="https://www.portal.hminnovations.org/launch"
                                                    >
                                                        {" "}
                                                        this link
                                                    </a>{" "}
                                                    to view.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99A App Icon and Description New */}

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p>
                                                <a
                                                    className="specialUseBackToTop"
                                                    onClick={() =>
                                                        window.scrollTo({top: 0, behavior: "smooth"})
                                                    }
                                                >
                                                    BACK TO TOP{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default LivingWellCenter6_5;
