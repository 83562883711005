//import variables from 'scss/helpers/variables.scss';
import React, { Fragment, useState } from "react";
import Home from "./components/home/index.js";
import "./scss/App.scss";
import "./scss/orbit-app-build.scss";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import "bootstrap/dist/css/bootstrap.min.css";
import PrivateRoute from "./components/routing/privateroute/index.js";
import PublicRoute from "./components/routing/publicroute/index.js";
import Profile from "./components/auth/profile/index.js";
import jwt_decode from "jwt-decode";
import { logout } from "./middleware/auth/index.js";
import { ErrorBoundary } from "react-error-boundary";
import BackToTop from "react-back-to-top-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  BrowserRouter,
  useRoutes,
} from "react-router-dom";
import OrbitCluster from "./components/orbitCluster/index.js";
import UserInvite from "./components/userinvite/index.js";
import SitemanPancreaticAll2_0 from "./components/sitemanPancreatic/siteman_pancreatic_all-2-0.js";
import Content from "./components/sitemanPancreatic/content/index.js";
import ContentPancreatic2 from "./components/sitemanPancreatic/contentpancreatic2";
import ContentPancreatic3 from "./components/sitemanPancreatic/contentpancreatic3";
import ContentPancreatic4 from "./components/sitemanPancreatic/contentpancreatic4";
import ContentPancreatic5 from "./components/sitemanPancreatic/contentpancreatic5";
import ContentPancreatic6 from "./components/sitemanPancreatic/contentpancreatic6";
import ContentPancreatic7 from "./components/sitemanPancreatic/contentpancreatic7";
import ContentPancreatic8 from "./components/sitemanPancreatic/contentpancreatic8";
import ContentPancreatic9 from "./components/sitemanPancreatic/contentpancreatic9";
import ContentPancreatic10 from "./components/sitemanPancreatic/contentpancreatic10";
import ContentPancreatic11 from "./components/sitemanPancreatic/contentpancreatic11";
import ContentPancreatic12 from "./components/sitemanPancreatic/contentpancreatic12";
import ContentPancreatic13 from "./components/sitemanPancreatic/contentpancreatic13";
import ContentPancreatic14 from "./components/sitemanPancreatic/contentpancreatic14";
import ContentPancreatic15 from "./components/sitemanPancreatic/contentpancreatic15";
import ContentPancreatic16 from "./components/sitemanPancreatic/contentpancreatic16";
import ContentPancreatic17 from "./components/sitemanPancreatic/contentpancreatic17";
import ContentPancreatic18 from "./components/sitemanPancreatic/contentpancreatic18";
import ContentPancreatic19 from "./components/sitemanPancreatic/contentpancreatic19";
import ContentPancreatic20 from "./components/sitemanPancreatic/contentpancreatic20";
import ContentPancreatic21 from "./components/sitemanPancreatic/contentpancreatic21";
import ContentPancreatic22 from "./components/sitemanPancreatic/contentpancreatic22";
import ContentPancreatic23 from "./components/sitemanPancreatic/contentpancreatic23";
import ContentPancreatic24 from "./components/sitemanPancreatic/contentpancreatic24";
import ContentPancreatic25 from "./components/sitemanPancreatic/contentpancreatic25";
import ContentPancreatic26 from "./components/sitemanPancreatic/contentpancreatic26";
import ContentPancreatic27 from "./components/sitemanPancreatic/contentpancreatic27";
import ContentPancreatic28 from "./components/sitemanPancreatic/contentpancreatic28";
import ContentPancreatic29 from "./components/sitemanPancreatic/contentpancreatic29";
/*Lesson Disposal*/
import LessonDisposal from "./components/lessons/lesson-1-1-Disposal";
import LessonDisposalWhatYouNeedToKnow from "./components/lessons/lesson-1-1-Disposal/what-you-need-to-know";
import LessonDisposalIntroduction from "./components/lessons/lesson-1-1-Disposal/introduction";
import LessonDisposalWhatToTellYourDoctor from "./components/lessons/lesson-1-1-Disposal/what-to-tell-your-doctor";
import LessonDisposalFAQResources from "./components/lessons/lesson-1-1-Disposal/FAQ-Resources";
import LessonDisposalWhatToDoAndSafety from "./components/lessons/lesson-1-1-Disposal/what-to-do-and-safety";
/*Lesson Fall Prevention*/
import LessonFallPrevention from "./components/lessons/lesson-1-2-Fall-Prevention";
import LessonFallPreventionWhatToDoAndSafety from "./components/lessons/lesson-1-2-Fall-Prevention/what-to-do-and-safety";
import LessonFallPreventionIntroduction from "./components/lessons/lesson-1-2-Fall-Prevention/introduction";
import LessonFallPreventionWhatToTellYourDoctor from "./components/lessons/lesson-1-2-Fall-Prevention/what-to-tell-your-doctor";
import LessonFallPreventionWhatYouNeedToKnow from "./components/lessons/lesson-1-2-Fall-Prevention/what-you-need-to-know";
import LessonFallPreventionFAQResources from "./components/lessons/lesson-1-2-Fall-Prevention/FAQ-Resources";
/*Lesson Infection Control*/
import LessonInfection from "./components/lessons/lesson-1-3-Infection";
import LessonInfectionWhatYouNeedToKnow from "./components/lessons/lesson-1-3-Infection/what-you-need-to-know";
import LessonInfectionIntroduction from "./components/lessons/lesson-1-3-Infection/introduction";
import LessonInfectionWhatToTellYourDoctor from "./components/lessons/lesson-1-3-Infection/what-to-tell-your-doctor";
import LessonInfectionFAQResources from "./components/lessons/lesson-1-3-Infection/FAQ-Resources";
import LessonInfectionWhatToDoAndSafety from "./components/lessons/lesson-1-3-Infection/what-to-do-and-safety";
/*Lesson Walkers*/
import LessonWalkers from "./components/lessons/lesson-1-4-Walkers";
import LessonWalkersIntroduction from "./components/lessons/lesson-1-4-Walkers/introduction";
import LessonWalkersWhatToTellYourDoctor from "./components/lessons/lesson-1-4-Walkers/what-to-tell-your-doctor";
import LessonWalkersWhatYouNeedToKnow from "./components/lessons/lesson-1-4-Walkers/what-you-need-to-know";
import LessonWalkersFAQResources from "./components/lessons/lesson-1-4-Walkers/FAQ-Resources";
import LessonWalkersWhatToDoAndSafety from "./components/lessons/lesson-1-4-Walkers/what-to-do-and-safety";
/*Lesson Moving*/
import LessonMoving from "./components/lessons/lesson-1-5-Moving";
import LessonMovingIntroduction from "./components/lessons/lesson-1-5-Moving/introduction";
import LessonMovingWhatToTellYourDoctor from "./components/lessons/lesson-1-5-Moving/what-to-tell-your-doctor";
import LessonMovingWhatYouNeedToKnow from "./components/lessons/lesson-1-5-Moving/what-you-need-to-know";
import LessonMovingFAQResources from "./components/lessons/lesson-1-5-Moving/FAQ-Resources";
import LessonMovingWhatToDoAndSafety from "./components/lessons/lesson-1-5-Moving/what-to-do-and-safety";
/*Lesson Wheelchairs*/
import LessonWheelchairs from "./components/lessons/lesson-1-6-Wheelchairs";
import LessonWheelchairsIntroduction from "./components/lessons/lesson-1-6-Wheelchairs/introduction";
import LessonWheelchairsWhatToTellYourDoctor from "./components/lessons/lesson-1-6-Wheelchairs/what-to-tell-your-doctor";
import LessonWheelchairsWhatYouNeedToKnow from "./components/lessons/lesson-1-6-Wheelchairs/what-you-need-to-know";
import LessonWheelchairsFAQResources from "./components/lessons/lesson-1-6-Wheelchairs/FAQ-Resources";
import LessonWheelchairsWhatToDoAndSafety from "./components/lessons/lesson-1-6-Wheelchairs/what-to-do-and-safety";
/*Lesson Wandering*/
import LessonWandering from "./components/lessons/lesson-1-7-Wandering";
import LessonWanderingIntroduction from "./components/lessons/lesson-1-7-Wandering/introduction";
import LessonWanderingWhatToTellYourDoctor from "./components/lessons/lesson-1-7-Wandering/what-to-tell-your-doctor";
import LessonWanderingWhatYouNeedToKnow from "./components/lessons/lesson-1-7-Wandering/what-you-need-to-know";
import LessonWanderingFAQResources from "./components/lessons/lesson-1-7-Wandering/FAQ-Resources";
import LessonWanderingWhatToDoAndSafety from "./components/lessons/lesson-1-7-Wandering/what-to-do-and-safety";
/*Lesson Dressing*/
import LessonDressing from "./components/lessons/lesson-1-8-Dressing";
import LessonDressingIntroduction from "./components/lessons/lesson-1-8-Dressing/introduction";
import LessonDressingWhatToTellYourDoctor from "./components/lessons/lesson-1-8-Dressing/what-to-tell-your-doctor";
import LessonDressingWhatYouNeedToKnow from "./components/lessons/lesson-1-8-Dressing/what-you-need-to-know";
import LessonDressingFAQResources from "./components/lessons/lesson-1-8-Dressing/FAQ-Resources";
import LessonDressingWhatToDoAndSafety from "./components/lessons/lesson-1-8-Dressing/what-to-do-and-safety";
/*Lesson Foot Care*/
import LessonFootCare from "./components/lessons/lesson-1-9-Foot-Care";
import LessonFootCareIntroduction from "./components/lessons/lesson-1-9-Foot-Care/introduction";
import LessonFootCareWhatToTellYourDoctor from "./components/lessons/lesson-1-9-Foot-Care/what-to-tell-your-doctor";
import LessonFootCareWhatYouNeedToKnow from "./components/lessons/lesson-1-9-Foot-Care/what-you-need-to-know";
import LessonFootCareFAQResources from "./components/lessons/lesson-1-9-Foot-Care/FAQ-Resources";
import LessonFootCareWhatToDoAndSafety from "./components/lessons/lesson-1-9-Foot-Care/what-to-do-and-safety";
/*Lesson Mouth Care*/
import LessonMouthCare from "./components/lessons/lesson-1-10-Mouth-Care";
import LessonMouthCareIntroduction from "./components/lessons/lesson-1-10-Mouth-Care/introduction";
import LessonMouthCareWhatToTellYourDoctor from "./components/lessons/lesson-1-10-Mouth-Care/what-to-tell-your-doctor";
import LessonMouthCareWhatYouNeedToKnow from "./components/lessons/lesson-1-10-Mouth-Care/what-you-need-to-know";
import LessonMouthCareFAQResources from "./components/lessons/lesson-1-10-Mouth-Care/FAQ-Resources";
import LessonMouthCareWhatToDoAndSafety from "./components/lessons/lesson-1-10-Mouth-Care/what-to-do-and-safety";
/*Lesson Pain*/
import LessonPain from "./components/lessons/lesson-1-11-Pain";
import LessonPainIntroduction from "./components/lessons/lesson-1-11-Pain/introduction";
import LessonPainWhatToTellYourDoctor from "./components/lessons/lesson-1-11-Pain/what-to-tell-your-doctor";
import LessonPainWhatYouNeedToKnow from "./components/lessons/lesson-1-11-Pain/what-you-need-to-know";
import LessonPainFAQResources from "./components/lessons/lesson-1-11-Pain/FAQ-Resources";
import LessonPainWhatToDoAndSafety from "./components/lessons/lesson-1-11-Pain/what-to-do-and-safety";
/*Lesson Oxygen*/
import LessonOxygen from "./components/lessons/lesson-1-12-Oxygen";
import LessonOxygenIntroduction from "./components/lessons/lesson-1-12-Oxygen/introduction";
import LessonOxygenWhatToTellYourDoctor from "./components/lessons/lesson-1-12-Oxygen/what-to-tell-your-doctor";
import LessonOxygenWhatYouNeedToKnow from "./components/lessons/lesson-1-12-Oxygen/what-you-need-to-know";
import LessonOxygenFAQResources from "./components/lessons/lesson-1-12-Oxygen/FAQ-Resources";
import LessonOxygenWhatToDoAndSafety from "./components/lessons/lesson-1-12-Oxygen/what-to-do-and-safety";
/*Lesson Enema*/
import LessonEnema from "./components/lessons/lesson-1-13-Enema";
import LessonEnemaIntroduction from "./components/lessons/lesson-1-13-Enema/introduction";
import LessonEnemaWhatToTellYourDoctor from "./components/lessons/lesson-1-13-Enema/what-to-tell-your-doctor";
import LessonEnemaWhatYouNeedToKnow from "./components/lessons/lesson-1-13-Enema/what-you-need-to-know";
import LessonEnemaFAQResources from "./components/lessons/lesson-1-13-Enema/FAQ-Resources";
import LessonEnemaWhatToDoAndSafety from "./components/lessons/lesson-1-13-Enema/what-to-do-and-safety";
/*Lesson Observing*/
import LessonObserving from "./components/lessons/lesson-2-1-Observing";
import LessonObservingIntroduction from "./components/lessons/lesson-2-1-Observing/introduction";
import LessonObservingWhatToTellYourDoctor from "./components/lessons/lesson-2-1-Observing/what-to-tell-your-doctor";
import LessonObservingWhatYouNeedToKnow from "./components/lessons/lesson-2-1-Observing/what-you-need-to-know";
import LessonObservingFAQResources from "./components/lessons/lesson-2-1-Observing/FAQ-Resources";
import LessonObservingWhatToDoAndSafety from "./components/lessons/lesson-2-1-Observing/what-to-do-and-safety";
/*Lesson Blood Pressure */
import LessonBloodPressure from "./components/lessons/lesson-2-2-Blood-Pressure";
import LessonBloodPressureIntroduction from "./components/lessons/lesson-2-2-Blood-Pressure/introduction";
import LessonBloodPressureWhatToTellYourDoctor from "./components/lessons/lesson-2-2-Blood-Pressure/what-to-tell-your-doctor";
import LessonBloodPressureWhatYouNeedToKnow from "./components/lessons/lesson-2-2-Blood-Pressure/what-you-need-to-know";
import LessonBloodPressureFAQResources from "./components/lessons/lesson-2-2-Blood-Pressure/FAQ-Resources";
import LessonBloodPressureWhatToDoAndSafety from "./components/lessons/lesson-2-2-Blood-Pressure/what-to-do-and-safety";
/*Lesson Pulse*/
import LessonPulse from "./components/lessons/lesson-2-3-Pulse";
import LessonPulseIntroduction from "./components/lessons/lesson-2-3-Pulse/introduction";
import LessonPulseWhatToTellYourDoctor from "./components/lessons/lesson-2-3-Pulse/what-to-tell-your-doctor";
import LessonPulseWhatYouNeedToKnow from "./components/lessons/lesson-2-3-Pulse/what-you-need-to-know";
import LessonPulseFAQResources from "./components/lessons/lesson-2-3-Pulse/FAQ-Resources";
import LessonPulseWhatToDoAndSafety from "./components/lessons/lesson-2-3-Pulse/what-to-do-and-safety";
/*Lesson Temperature*/
import LessonTemperature from "./components/lessons/lesson-2-4-Temperature";
import LessonTemperatureIntroduction from "./components/lessons/lesson-2-4-Temperature/introduction";
import LessonTemperatureWhatToTellYourDoctor from "./components/lessons/lesson-2-4-Temperature/what-to-tell-your-doctor";
import LessonTemperatureWhatYouNeedToKnow from "./components/lessons/lesson-2-4-Temperature/what-you-need-to-know";
import LessonTemperatureFAQResources from "./components/lessons/lesson-2-4-Temperature/FAQ-Resources";
import LessonTemperatureWhatToDoAndSafety from "./components/lessons/lesson-2-4-Temperature/what-to-do-and-safety";
/*Lesson Glucose*/
import LessonGlucose from "./components/lessons/lesson-2-5-Glucose";
import LessonGlucoseIntroduction from "./components/lessons/lesson-2-5-Glucose/introduction";
import LessonGlucoseWhatToTellYourDoctor from "./components/lessons/lesson-2-5-Glucose/what-to-tell-your-doctor";
import LessonGlucoseWhatYouNeedToKnow from "./components/lessons/lesson-2-5-Glucose/what-you-need-to-know";
import LessonGlucoseFAQResources from "./components/lessons/lesson-2-5-Glucose/FAQ-Resources";
import LessonGlucoseWhatToDoAndSafety from "./components/lessons/lesson-2-5-Glucose/what-to-do-and-safety";
/*Lesson Getting Ready*/
import LessonGettingReady from "./components/lessons/lesson-3-1-Getting-Ready";
import LessonGettingReadyIntroduction from "./components/lessons/lesson-3-1-Getting-Ready/introduction";
import LessonGettingReadyWhatToTellYourDoctor from "./components/lessons/lesson-3-1-Getting-Ready/what-to-tell-your-doctor";
import LessonGettingReadyWhatYouNeedToKnow from "./components/lessons/lesson-3-1-Getting-Ready/what-you-need-to-know";
import LessonGettingReadyFAQResources from "./components/lessons/lesson-3-1-Getting-Ready/FAQ-Resources";
import LessonGettingReadyWhatToDoAndSafety from "./components/lessons/lesson-3-1-Getting-Ready/what-to-do-and-safety";
/*Lesson Stockings*/
import LessonStockings from "./components/lessons/lesson-3-2-Stockings";
import LessonStockingsIntroduction from "./components/lessons/lesson-3-2-Stockings/introduction";
import LessonStockingsWhatToTellYourDoctor from "./components/lessons/lesson-3-2-Stockings/what-to-tell-your-doctor";
import LessonStockingsWhatYouNeedToKnow from "./components/lessons/lesson-3-2-Stockings/what-you-need-to-know";
import LessonStockingsFAQResources from "./components/lessons/lesson-3-2-Stockings/FAQ-Resources";
import LessonStockingsWhatToDoAndSafety from "./components/lessons/lesson-3-2-Stockings/what-to-do-and-safety";
/*Lesson After Surgery*/
import LessonAfterSurgery from "./components/lessons/lesson-3-3-After-Surgery";
import LessonAfterSurgeryIntroduction from "./components/lessons/lesson-3-3-After-Surgery/introduction";
import LessonAfterSurgeryWhatToTellYourDoctor from "./components/lessons/lesson-3-3-After-Surgery/what-to-tell-your-doctor";
import LessonAfterSurgeryWhatYouNeedToKnow from "./components/lessons/lesson-3-3-After-Surgery/what-you-need-to-know";
import LessonAfterSurgeryFAQResources from "./components/lessons/lesson-3-3-After-Surgery/FAQ-Resources";
import LessonAfterSurgeryWhatToDoAndSafety from "./components/lessons/lesson-3-3-After-Surgery/what-to-do-and-safety";
/*Lesson Drainage*/
import LessonDrainage from "./components/lessons/lesson-3-4-Drainage";
import LessonDrainageIntroduction from "./components/lessons/lesson-3-4-Drainage/introduction";
import LessonDrainageWhatToTellYourDoctor from "./components/lessons/lesson-3-4-Drainage/what-to-tell-your-doctor";
import LessonDrainageWhatYouNeedToKnow from "./components/lessons/lesson-3-4-Drainage/what-you-need-to-know";
import LessonDrainageFAQResources from "./components/lessons/lesson-3-4-Drainage/FAQ-Resources";
import LessonDrainageWhatToDoAndSafety from "./components/lessons/lesson-3-4-Drainage/what-to-do-and-safety";
/*Lesson Stitches*/
import LessonStitches from "./components/lessons/lesson-3-5-Stitches";
import LessonStitchesIntroduction from "./components/lessons/lesson-3-5-Stitches/introduction";
import LessonStitchesWhatToTellYourDoctor from "./components/lessons/lesson-3-5-Stitches/what-to-tell-your-doctor";
import LessonStitchesWhatYouNeedToKnow from "./components/lessons/lesson-3-5-Stitches/what-you-need-to-know";
import LessonStitchesFAQResources from "./components/lessons/lesson-3-5-Stitches/FAQ-Resources";
import LessonStitchesWhatToDoAndSafety from "./components/lessons/lesson-3-5-Stitches/what-to-do-and-safety";
/*Lesson Gauze Dressings*/
import LessonGauzeDressing from "./components/lessons/lesson-3-6-Gauze-Dressings";
import LessonGauzeDressingIntroduction from "./components/lessons/lesson-3-6-Gauze-Dressings/introduction";
import LessonGauzeDressingWhatToTellYourDoctor from "./components/lessons/lesson-3-6-Gauze-Dressings/what-to-tell-your-doctor";
import LessonGauzeDressingWhatYouNeedToKnow from "./components/lessons/lesson-3-6-Gauze-Dressings/what-you-need-to-know";
import LessonGauzeDressingFAQResources from "./components/lessons/lesson-3-6-Gauze-Dressings/FAQ-Resources";
import LessonGauzeDressingWhatToDoAndSafety from "./components/lessons/lesson-3-6-Gauze-Dressings/what-to-do-and-safety";
/*Lesson Giving Medicines*/
import LessonGivingMedicines from "./components/lessons/lesson-4-1-Giving-Medicines";
import LessonGivingMedicinesIntroduction from "./components/lessons/lesson-4-1-Giving-Medicines/introduction";
import LessonGivingMedicinesWhatToTellYourDoctor from "./components/lessons/lesson-4-1-Giving-Medicines/what-to-tell-your-doctor";
import LessonGivingMedicinesWhatYouNeedToKnow from "./components/lessons/lesson-4-1-Giving-Medicines/what-you-need-to-know";
import LessonGivingMedicinesFAQResources from "./components/lessons/lesson-4-1-Giving-Medicines/FAQ-Resources";
import LessonGivingMedicinesWhatToDoAndSafety from "./components/lessons/lesson-4-1-Giving-Medicines/what-to-do-and-safety";
/*Lesson Inhalers*/
import LessonInhalers from "./components/lessons/lesson-4-2-Inhalers";
import LessonInhalersIntroduction from "./components/lessons/lesson-4-2-Inhalers/introduction";
import LessonInhalersWhatToTellYourDoctor from "./components/lessons/lesson-4-2-Inhalers/what-to-tell-your-doctor";
import LessonInhalersWhatYouNeedToKnow from "./components/lessons/lesson-4-2-Inhalers/what-you-need-to-know";
import LessonInhalersFAQResources from "./components/lessons/lesson-4-2-Inhalers/FAQ-Resources";
import LessonInhalersWhatToDoAndSafety from "./components/lessons/lesson-4-2-Inhalers/what-to-do-and-safety";
/*Lesson Nebulizers*/
import LessonNebulizers from "./components/lessons/lesson-4-3-Nebulizers";
import LessonNebulizersIntroduction from "./components/lessons/lesson-4-3-Nebulizers/introduction";
import LessonNebulizersWhatToTellYourDoctor from "./components/lessons/lesson-4-3-Nebulizers/what-to-tell-your-doctor";
import LessonNebulizersWhatYouNeedToKnow from "./components/lessons/lesson-4-3-Nebulizers/what-you-need-to-know";
import LessonNebulizersFAQResources from "./components/lessons/lesson-4-3-Nebulizers/FAQ-Resources";
import LessonNebulizersWhatToDoAndSafety from "./components/lessons/lesson-4-3-Nebulizers/what-to-do-and-safety";
/*Lesson Suppositories*/
import LessonSuppositories from "./components/lessons/lesson-4-4-Suppositories";
import LessonSuppositoriesIntroduction from "./components/lessons/lesson-4-4-Suppositories/introduction";
import LessonSuppositoriesWhatToTellYourDoctor from "./components/lessons/lesson-4-4-Suppositories/what-to-tell-your-doctor";
import LessonSuppositoriesWhatYouNeedToKnow from "./components/lessons/lesson-4-4-Suppositories/what-you-need-to-know";
import LessonSuppositoriesFAQResources from "./components/lessons/lesson-4-4-Suppositories/FAQ-Resources";
import LessonSuppositoriesWhatToDoAndSafety from "./components/lessons/lesson-4-4-Suppositories/what-to-do-and-safety";
/*Lesson Subcutaneous Injections*/
import LessonSubcutaneousInjections from "./components/lessons/lesson-4-5-Subcutaneous-Injections";
import LessonSubcutaneousInjectionsIntroduction from "./components/lessons/lesson-4-5-Subcutaneous-Injections/introduction";
import LessonSubcutaneousInjectionsWhatToTellYourDoctor from "./components/lessons/lesson-4-5-Subcutaneous-Injections/what-to-tell-your-doctor";
import LessonSubcutaneousInjectionsWhatYouNeedToKnow from "./components/lessons/lesson-4-5-Subcutaneous-Injections/what-you-need-to-know";
import LessonSubcutaneousInjectionsFAQResources from "./components/lessons/lesson-4-5-Subcutaneous-Injections/FAQ-Resources";
import LessonSubcutaneousInjectionsWhatToDoAndSafety from "./components/lessons/lesson-4-5-Subcutaneous-Injections/what-to-do-and-safety";
/*Lesson Aspiration*/
import LessonAspiration from "./components/lessons/lesson-6-1-Aspiration";
import LessonAspirationIntroduction from "./components/lessons/lesson-6-1-Aspiration/introduction";
import LessonAspirationWhatToTellYourDoctor from "./components/lessons/lesson-6-1-Aspiration/what-to-tell-your-doctor";
import LessonAspirationWhatYouNeedToKnow from "./components/lessons/lesson-6-1-Aspiration/what-you-need-to-know";
import LessonAspirationFAQResources from "./components/lessons/lesson-6-1-Aspiration/FAQ-Resources";
import LessonAspirationWhatToDoAndSafety from "./components/lessons/lesson-6-1-Aspiration/what-to-do-and-safety";
/*Lesson Feeding a Person*/
import LessonFeedingAPerson from "./components/lessons/lesson-6-2-Feeding-A-Person";
import LessonFeedingAPersonIntroduction from "./components/lessons/lesson-6-2-Feeding-A-Person/introduction";
import LessonFeedingAPersonWhatToTellYourDoctor from "./components/lessons/lesson-6-2-Feeding-A-Person/what-to-tell-your-doctor";
import LessonFeedingAPersonWhatYouNeedToKnow from "./components/lessons/lesson-6-2-Feeding-A-Person/what-you-need-to-know";
import LessonFeedingAPersonFAQResources from "./components/lessons/lesson-6-2-Feeding-A-Person/FAQ-Resources";
import LessonFeedingAPersonWhatToDoAndSafety from "./components/lessons/lesson-6-2-Feeding-A-Person/what-to-do-and-safety";
import Lessons from "./components/lessons/lessons";

import homeCluster from "./components/sitemanENT/";
import HomeCluster from "./components/sitemanENT/";
import SitemanENTAll1_2 from "./components/sitemanENT/siteman_ent_all-1-2";
import SitemanENTAll1_3 from "./components/sitemanENT/siteman_ent_all-1-3";
import SitemanENTAll1_4 from "./components/sitemanENT/siteman_ent_all-1-4";
import SitemanENTAll1_5 from "./components/sitemanENT/siteman_ent_all-1-5";
import SitemanENTTGSP1_6 from "./components/sitemanENT/siteman_ent_tgsp-1-6";
import SitemanENTAll2_4 from "./components/sitemanENT/siteman_ent_all-2-4";
import SitemanENTAll3_6 from "./components/sitemanENT/siteman_ent_all-3-6";
import SitemanENTAll3_7 from "./components/sitemanENT/siteman_ent_all-3-7";
import SitemanENTAll4_1_3 from "./components/sitemanENT/siteman_ent_all-4-1-3";
import SitemanENTAll4_2_2 from "./components/sitemanENT/siteman_ent_all-4-2-2";
import SitemanENTAll4_2_3 from "./components/sitemanENT/siteman_ent_all-4-2-3";
import SitemanENTAll4_2_4 from "./components/sitemanENT/siteman_ent_all-4-2-4";
import SitemanENTAll4_2_5 from "./components/sitemanENT/siteman_ent_all-4-2-5";
import SitemanENTAll4_2_7 from "./components/sitemanENT/siteman_ent_all-4-2-7";
import SitemanENTAll4_2_9 from "./components/sitemanENT/siteman_ent_all-4-2-9";
import SitemanENTAll4_4 from "./components/sitemanENT/siteman_ent_all-4-4";
import SitemanENTAll4_6 from "./components/sitemanENT/siteman_ent_all-4-6";
import SitemanENTAll4_7 from "./components/sitemanENT/siteman_ent_all-4-7";
import SitemanENTAll7_3 from "./components/sitemanENT/siteman_ent_all-7-3";
import SitemanENTAll7_5 from "./components/sitemanENT/siteman_ent_all-7-5";
import SitemanENTAll7_6 from "./components/sitemanENT/siteman_ent_all-7-6";
import SitemanENTAll8_1 from "./components/sitemanENT/siteman_ent_all-8-1";
import SitemanENTAll8_2 from "./components/sitemanENT/siteman_ent_all-8-2";
import SitemanENTAll8_3 from "./components/sitemanENT/siteman_ent_all-8-3";
import SitemanENTAll8_5 from "./components/sitemanENT/siteman_ent_all-8-5";
import SitemanENTAll8_6 from "./components/sitemanENT/siteman_ent_all-8-6";
import SitemanENTAll8_7 from "./components/sitemanENT/siteman_ent_all-8-7";
import SitemanENTAll8_8 from "./components/sitemanENT/siteman_ent_all-8-8";
import SitemanENTAll8_9 from "./components/sitemanENT/siteman_ent_all-8-9";
import SitemanENTAll9_1 from "./components/sitemanENT/siteman_ent_all-9-1";
import SitemanENTAll9_3 from "./components/sitemanENT/siteman_ent_all-9-3";
import SitemanENTAll9_4 from "./components/sitemanENT/siteman_ent_all-9-4";
import SitemanENTAll9_5 from "./components/sitemanENT/siteman_ent_all-9-5";
import SitemanENTAll9_6 from "./components/sitemanENT/siteman_ent_all-9-6";
import SitemanENTTGSP1_1 from "./components/sitemanENT/siteman_ent_tgsp-1-1";
import SitemanENTTGSP1_3 from "./components/sitemanENT/siteman_ent_tgsp-1-3";
import SitemanENTTGSP1_4 from "./components/sitemanENT/siteman_ent_tgsp-1-4";
import SitemanENTTGSP1_5 from "./components/sitemanENT/siteman_ent_tgsp-1-5";
import SitemanENTTGSP2_1 from "./components/sitemanENT/siteman_ent_tgsp-2-1";
import SitemanENTTGSP2_2 from "./components/sitemanENT/siteman_ent_tgsp-2-2";
import SitemanENTTGSP2_3 from "./components/sitemanENT/siteman_ent_tgsp-2-3";
import SitemanENTTGSP2_3_1 from "./components/sitemanENT/siteman_ent_tgsp-2-3-1";
import SitemanENTTGSP2_3_2 from "./components/sitemanENT/siteman_ent_tgsp-2-3-2";
import SitemanENTTGSP2_3_3 from "./components/sitemanENT/siteman_ent_tgsp-2-3-3";
import SitemanENTTGSP2_3_4 from "./components/sitemanENT/siteman_ent_tgsp-2-3-4";
import SitemanENTTGSP2_5 from "./components/sitemanENT/siteman_ent_tgsp-2-5";
import SitemanENTTGSP2_6 from "./components/sitemanENT/siteman_ent_tgsp-2-6";
import SitemanENTTGSP3_1 from "./components/sitemanENT/siteman_ent_tgsp-3-1";
import SitemanENTTGSP3_6 from "./components/sitemanENT/siteman_ent_tgsp-3-6";
import SitemanENTTGSP4_1_1 from "./components/sitemanENT/siteman_ent_tgsp-4-1-1";
import SitemanENTTGSP4_1_5 from "./components/sitemanENT/siteman_ent_tgsp-4-1-5";
import SitemanENTTGSP4_1_6 from "./components/sitemanENT/siteman_ent_tgsp-4-1-6";
import SitemanENTTGSP4_2_1 from "./components/sitemanENT/siteman_ent_tgsp-4-2-1";
import SitemanENTTGSP4_2_4 from "./components/sitemanENT/siteman_ent_tgsp-4-2-4";
import SitemanENTTGSP4_3 from "./components/sitemanENT/siteman_ent_tgsp-4-3";
import SitemanENTTGSP4_5 from "./components/sitemanENT/siteman_ent_tgsp-4-5";
import SitemanENTTGSP6_0 from "./components/sitemanENT/siteman_ent_tgsp-6-0";
import SitemanENTTGSP7_1 from "./components/sitemanENT/siteman_ent_tgsp-7-1";
import SitemanENTTGSP7_2 from "./components/sitemanENT/siteman_ent_tgsp-7-2";
import SitemanENTTGSP7_4 from "./components/sitemanENT/siteman_ent_tgsp-7-4";
import SitemanENTTL1_1 from "./components/sitemanENT/siteman_ent_tl-1-1";
import SitemanENTTL1_3 from "./components/sitemanENT/siteman_ent_tl-1-3";
import SitemanENTTL1_4 from "./components/sitemanENT/siteman_ent_tl-1-4";
import SitemanENTTL1_5 from "./components/sitemanENT/siteman_ent_tl-1-5";
import SitemanENTTL2_1 from "./components/sitemanENT/siteman_ent_tl-2-1";
import SitemanENTTL2_2 from "./components/sitemanENT/siteman_ent_tl-2-2";
import SitemanENTTL2_3 from "./components/sitemanENT/siteman_ent_tl-2-3";
import SitemanENTTL2_4 from "./components/sitemanENT/siteman_ent_tl-2-4";
import SitemanENTTL2_5 from "./components/sitemanENT/siteman_ent_tl-2-5-new";
import SitemanENTTL2_6 from "./components/sitemanENT/siteman_ent_tl-2-6";
import SitemanENTTL3_1 from "./components/sitemanENT/siteman_ent_tl-3-1";
import SitemanENTTL3_2 from "./components/sitemanENT/siteman_ent_tl-3-2";
import SitemanENTTL3_6 from "./components/sitemanENT/siteman_ent_tl-3-6";
import SitemanENTTL4_1_1 from "./components/sitemanENT/siteman_ent_tl-4-1-1";
import SitemanENTTL4_1_6 from "./components/sitemanENT/siteman_ent_tl-4-1-6";
import SitemanENTTL4_2_1 from "./components/sitemanENT/siteman_ent_tl-4-2-1";
import SitemanENTTL4_2_4 from "./components/sitemanENT/siteman_ent_tl-4-2-4";
import SitemanENTTL4_3 from "./components/sitemanENT/siteman_ent_tl-4-3";
import SitemanENTTL4_5 from "./components/sitemanENT/siteman_ent_tl-4-5";
import SitemanENTTL6_0 from "./components/sitemanENT/siteman_ent_tl-6-0";
import SitemanENTTL7_1 from "./components/sitemanENT/siteman_ent_tl-7-1";
import SitemanENTTL7_2 from "./components/sitemanENT/siteman_ent_tl-7-2";
import SitemanENTTL7_4 from "./components/sitemanENT/siteman_ent_tl-7-4";
import SitemanENTTL7_8 from "./components/sitemanENT/siteman_ent_tl-7-8";
import SitemanENTNotCompleted from "./components/sitemanENT/siteman_page-not-completed";
import SitemanEntAll1_0 from "./components/sitemanENT/siteman_ent_all-1-0";
import SitemanEntAll2_0 from "./components/sitemanENT/siteman_ent_all-2-0";
import SitemanEntAll3_0 from "./components/sitemanENT/siteman_ent_all-3-0";
import SitemanEntAll4_0 from "./components/sitemanENT/siteman_ent_all-4-0";
import SitemanEntAll4_1B from "./components/sitemanENT/siteman_ent_all-4-1B";
import SitemanEntAll4_1A from "./components/sitemanENT/siteman_ent_all-4-1A";
import SitemanEntAll4_1C from "./components/sitemanENT/siteman_ent_all-4-1C";
import SitemanENTALL4_1_2 from "./components/sitemanENT/siteman_ent_all-4-1-2";
import SitemanEntAll4_2 from "./components/sitemanENT/siteman_ent_all-4-2";
import SitemanEntAll7_0 from "./components/sitemanENT/siteman_ent_all-7-0";
import SitemanEntAll8_0 from "./components/sitemanENT/siteman_ent_all-8-0";
import SitemanEntAll9_0 from "./components/sitemanENT/siteman_ent_all-9.0";
import SitemanPancreaticAll2_1 from "./components/sitemanPancreatic/siteman_pancreatic_all-2-1";
import SitemanPancreaticAll2_2 from "./components/sitemanPancreatic/siteman_pancreatic_all-2-2";
import SitemanPancreaticAll2_3 from "./components/sitemanPancreatic/siteman_pancreatic_all-2-3";
import SitemanPancreaticAll2_3_1 from "./components/sitemanPancreatic/siteman_pancreatic_all-2-3-1";
import SitemanPancreaticAll2_3_2 from "./components/sitemanPancreatic/siteman_pancreatic_all-2-3-2";
import SitemanPancreaticAll7_1 from "./components/sitemanPancreatic/siteman_pancreatic_all-7-1";
import SitemanPancreaticAll7_3 from "./components/sitemanPancreatic/siteman_pancreatic_all-7-3";
import SitemanPancreaticAll7_4 from "./components/sitemanPancreatic/siteman_pancreatic_all-7-4";
import SitemanPancreaticAll7_5 from "./components/sitemanPancreatic/siteman_pancreatic_all-7-5";
import SitemanPancreaticRamps1_1 from "./components/sitemanPancreatic/siteman_pancreatic_ramps1_1";
import SitemanPancreaticAll9_0 from "./components/sitemanPancreatic/siteman_pancreatic_all-9.0";
import PancreaticVideoWhipple from "./components/sitemanPancreatic/siteman_pancreatic_video_whipple";
import PancreaticVideoRAMPS from "./components/sitemanPancreatic/siteman_pancreatic_video_ramps";
import PancreaticVideoGettingReadyForSurgery from "./components/sitemanPancreatic/siteman_pancreatic_video_getting-ready-for-surgery";
import PancreaticVideoHavingASuccessfulHospitalStay from "./components/sitemanPancreatic/siteman_pancreatic_video_successful-hospital-stay";
import PancreaticVideoYourTherapy from "./components/sitemanPancreatic/siteman_pancreatic_video_therapy";
import PancreaticVideoCareAfterSurgery from "./components/sitemanPancreatic/siteman_pancreatic_video_care-after-surgery";
import PancreaticVideoCareOfYourDrain from "./components/sitemanPancreatic/siteman_pancreatic_video_care-of-your-drains";
import PancreaticVideoGoingHomeWhatToExpect from "./components/sitemanPancreatic/siteman_pancreatic_video_going-home-what-to-expect";
import PancreaticVideoIncentiveSpirometry from "./components/sitemanPancreatic/siteman_pancreatic_video_incentive-spirometry.js";
import SitemanPancreaticAll2_3_3 from "./components/sitemanPancreatic/siteman_pancreatic_all-2-3-3";

//Pritikin
import Pritikin1_1 from "./components/pritikin/pritikin-1-1";
import Pritikin1_2 from "./components/pritikin/pritikin-1-2";
import Pritikin1_3 from "./components/pritikin/pritikin-1-3";
import Pritikin1_4 from "./components/pritikin/pritikin-1-4";
import Pritikin2_1 from "./components/pritikin/pritikin-2-1";
import Pritikin2_2 from "./components/pritikin/pritikin-2-2";
import Pritikin2_3 from "./components/pritikin/pritikin-2-3";
import Pritikin2_4 from "./components/pritikin/pritikin-2-4";
import Pritikin2_5 from "./components/pritikin/pritikin-2-5";
import Pritikin3_0 from "./components/pritikin/pritikin-3-0";
import Pritikin4_1 from "./components/pritikin/pritikin-4-1";
import Pritikin4_2 from "./components/pritikin/pritikin-4-2";
import UnityPointPritikin4_2 from "./components/pritikin/unity-point-pritikin-4-2";
import ChiPritikin4_2 from "./components/pritikin/chi-pritikin-4-2";
import Pritikin4_3 from "./components/pritikin/pritikin-4-3";
import Pritikin4_4 from "./components/pritikin/pritikin-4-4";
import Pritikin6_0 from "./components/pritikin/pritikin-6-0";
import Pritikin7_1 from "./components/pritikin/pritikin-7-1";
import Pritikin7_2 from "./components/pritikin/pritikin-7-2";
import Pritikin7_4 from "./components/pritikin/pritikin-7-4";
import Pritikin9_0 from "./components/pritikin/pritikin-9-0";
// import Pritikin_landing from "./components/pritikin/pritikin-landing";

//legal
import TermsOfUse from "./components/legal/terms-of-use";
import PrivacyPolicy from "./components/legal/privacy-policy";
import CookiePolicy from "./components/legal/cookie-policy";

import TilePage from "./components/tilePage/index.js";
import PatientInvite from "./components/patientinvite/index.js";
import ClusterDemo from "./components/clusterDemo/index.js";
import Demo from "./components/demo/index.js";
import GenerateQrCodeUrl from "./components/generateQrCodeUrl";
import Pritikin_CHI_7_2 from "./components/pritikin/chi-pritikin-7-2";
import BOONE_HEALTH_Pritikin4_2 from "./components/pritikin/boone-health-pritikin-4-2";
import Pritikin_BOONE_HEALTH_7_2 from "./components/pritikin/boone-health-pritikin-7-2";
import Pritikin_BOONE_HEALTH_7_3 from "./components/pritikin/boone-health-pritikin-7-3";
import SitemanENTTL4_1_5 from "./components/sitemanENT/siteman_ent_tl-4-1-5";
import SitemanENTAll4_1_4 from "./components/sitemanENT/siteman_ent_all-4-1-4";
import SitemanENTTL1_6 from "./components/sitemanENT/siteman_ent_tl-1-6";
import AppContextProvider from "./context/AppContext.js";

//Fluoroscopy
import InjectionSupport1_1 from "./components/washUFluoroscopy/injection_support-1-1";
import InjectionSupport2_1 from "./components/washUFluoroscopy/injection_support-2-1";
import InjectionSupport2_2 from "./components/washUFluoroscopy/injection_support-2-2";
import InjectionSupport2_3_1 from "./components/washUFluoroscopy/injection_support-2-3-1";
import InjectionSupport2_3_1_dr_sookochoff from "./components/washUFluoroscopy/injection_support-2-3-1-dr-sookochoff";
import InjectionSupport2_3_2_dr_sookochoff from "./components/washUFluoroscopy/injection_support-2-3-2-dr-sookochoff";
import InjectionSupport2_3_3_dr_sookochoff from "./components/washUFluoroscopy/injection_support-2-3-3-dr-sookochoff";
import InjectionSupport2_3_5_dr_sookochoff from "./components/washUFluoroscopy/injection_support-2-3-5-dr-sookochoff";
import InjectionSupport2_3_6_dr_sookochoff from "./components/washUFluoroscopy/injection_support-2-3-6-dr-sookochoff";
import InjectionSupport2_3_2 from "./components/washUFluoroscopy/injection_support-2-3-2";
import InjectionSupport2_3_3 from "./components/washUFluoroscopy/injection_support-2-3-3";
import InjectionSupport2_3_4 from "./components/washUFluoroscopy/injection_support-2-3-4";
import InjectionSupport2_3_5 from "./components/washUFluoroscopy/injection_support-2-3-5";
import InjectionSupport2_3_6 from "./components/washUFluoroscopy/injection_support-2-3-6";
import InjectionSupport2_5_1 from "./components/washUFluoroscopy/injection_support-2-5-1";
import InjectionSupport2_5_2 from "./components/washUFluoroscopy/injection_support-2-5-2";
import InjectionSupport2_5_3 from "./components/washUFluoroscopy/injection_support-2-5-3";
import InjectionSupport2_5_5 from "./components/washUFluoroscopy/injection_support-2-5-5";
import InjectionSupport2_6 from "./components/washUFluoroscopy/injection_support-2-6";
import InjectionSupport3 from "./components/washUFluoroscopy/injection_support-3";
import InjectionSupport4 from "./components/washUFluoroscopy/injection_support-4";
import InjectionSupport6 from "./components/washUFluoroscopy/injection_support-6";
import InjectionSupport2_6_1 from "./components/washUFluoroscopy/injection_support-2-6-1";
import InjectionSupport2_6_2 from "./components/washUFluoroscopy/injection_support-2-6-2";
import InjectionSupport2_6_3 from "./components/washUFluoroscopy/injection_support-2-6-3";
import InjectionSupport2_4 from "./components/washUFluoroscopy/injection_support-2-4";
import InjectionSupport2_5_4 from "./components/washUFluoroscopy/injection_support-2-5-4";
import InjectionSupport7 from "./components/washUFluoroscopy/injection_support-7";

//Living Well Center General
import LivingWellCenter1_1 from "./components/washULivingWellCenter/living_well_center-1-1.js";
import LivingWellCenter1_2 from "./components/washULivingWellCenter/living_well_center-1-2";
import LivingWellCenter1_3 from "./components/washULivingWellCenter/living_well_center-1-3";
import LivingWellCenter1_5 from "./components/washULivingWellCenter/living_well_center-1-5";
import LivingWellCenter2_1 from "./components/washULivingWellCenter/living_well_center-2-1";
import LivingWellCenter2_2 from "./components/washULivingWellCenter/living_well_center-2-2";
import LivingWellCenter2_3 from "./components/washULivingWellCenter/living_well_center-2-3";
import LivingWellCenter2_4 from "./components/washULivingWellCenter/living_well_center-2-4";
import LivingWellCenter3 from "./components/washULivingWellCenter/living_well_center-3";
import LivingWellCenter4_1 from "./components/washULivingWellCenter/living_well_center-4-1";
import LivingWellCenter4_2 from "./components/washULivingWellCenter/living_well_center-4-2";
import LivingWellCenter4_3 from "./components/washULivingWellCenter/living_well_center-4-3";
import LivingWellCenter4_4 from "./components/washULivingWellCenter/living_well_center-4-4";
import LivingWellCenter4_5 from "./components/washULivingWellCenter/living_well_center-4-5";
import LivingWellCenter6_1 from "./components/washULivingWellCenter/living_well_center-6-1";
import LivingWellCenter6_2 from "./components/washULivingWellCenter/living_well_center-6-2";
import LivingWellCenter6_3 from "./components/washULivingWellCenter/living_well_center-6-3";
import LivingWellCenter6_4 from "./components/washULivingWellCenter/living_well_center-6-4";
import LivingWellCenter6_5 from "./components/washULivingWellCenter/living_well_center-6-5";

import LivingWellCenter_6_1_long_covid from "./components/washULivingWellCenter/living_well_center_general-6-1-long-covid";
import LivingWellCenter_9 from "./components/washULivingWellCenter/living_well_center_general-9";
import LivingWellCenter_6_2c_cancer_survivorship
  from "./components/washULivingWellCenter/living_well_center_general-6-2-cancer-survivorship";

//legos
import LegosRefactored from "./components/legosRefactored-24/legos-refactored-24";
import ContentPage from "./components/HER/content-page";

//Setup for initial push

function App() {
  return (
    <Fragment>
      <AppContextProvider>
        <Routes>
          <Route path="*" element={<PublicRoute />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/userinvite" element={<UserInvite />} />

            <Route path="/patientinvite" element={<PatientInvite />} />

            <Route path="/generateQrCodeUrl" element={<GenerateQrCodeUrl />} />

            <Route path="/profile" element={<Profile />} />

            <Route path="/cluster/:id" element={<OrbitCluster />} />

            <Route path="/tilepage/:id" element={<TilePage />} />

            {/* Legos Refactored */}

            <Route path="/legosRefactored" element={<LegosRefactored />} />

            {/* End Legos Refactored */}

            {/* Injection Support */}

            <Route
              path="/injection_support-1-1"
              element={<InjectionSupport1_1 />}
            />

            <Route
              path="/injection_support-2-1"
              element={<InjectionSupport2_1 />}
            />

            <Route
              path="/injection_support-2-2"
              element={<InjectionSupport2_2 />}
            />

            <Route
              path="/injection_support-2-3-1"
              element={<InjectionSupport2_3_1 />}
            />

            <Route
              path="/injection_support-2-3-1-dr-sookochoff"
              element={<InjectionSupport2_3_1_dr_sookochoff />}
            />

            <Route
              path="/injection_support-2-3-2-dr-sookochoff"
              element={<InjectionSupport2_3_2_dr_sookochoff />}
            />

            <Route
              path="/injection_support-2-3-3-dr-sookochoff"
              element={<InjectionSupport2_3_3_dr_sookochoff />}
            />

            <Route
              path="/injection_support-2-3-5-dr-sookochoff"
              element={<InjectionSupport2_3_5_dr_sookochoff />}
            />
            <Route
              path="/injection_support-2-3-6-dr-sookochoff"
              element={<InjectionSupport2_3_6_dr_sookochoff />}
            />

            <Route
              path="/injection_support-2-3-2"
              element={<InjectionSupport2_3_2 />}
            />

            <Route
              path="/injection_support-2-3-3"
              element={<InjectionSupport2_3_3 />}
            />

            <Route
              path="/injection_support-2-3-4"
              element={<InjectionSupport2_3_4 />}
            />

            <Route
              path="/injection_support-2-3-5"
              element={<InjectionSupport2_3_5 />}
            />

            <Route
              path="/injection_support-2-3-6"
              element={<InjectionSupport2_3_6 />}
            />
            <Route
              path="/injection_support-2-4"
              element={<InjectionSupport2_4 />}
            />

            <Route
              path="/injection_support-2-5-1"
              element={<InjectionSupport2_5_1 />}
            />

            <Route
              path="/injection_support-2-5-2"
              element={<InjectionSupport2_5_2 />}
            />

            <Route
              path="/injection_support-2-5-3"
              element={<InjectionSupport2_5_3 />}
            />

            <Route
              path="/injection_support-2-5-4"
              element={<InjectionSupport2_5_4 />}
            />

            <Route
              path="/injection_support-2-5-5"
              element={<InjectionSupport2_5_5 />}
            />

            <Route
              path="/injection_support-2-6"
              element={<InjectionSupport2_6 />}
            />

            <Route
              path="/injection_support-2-6-1"
              element={<InjectionSupport2_6_1 />}
            />
            <Route
              path="/injection_support-2-6-2"
              element={<InjectionSupport2_6_2 />}
            />
            <Route
              path="/injection_support-2-6-3"
              element={<InjectionSupport2_6_3 />}
            />

            <Route
              path="/injection_support-3"
              element={<InjectionSupport3 />}
            />

            <Route
              path="/injection_support-4"
              element={<InjectionSupport4 />}
            />

            <Route
              path="/injection_support-6"
              element={<InjectionSupport6 />}
            />

            <Route
              path="/injection_support-7"
              element={<InjectionSupport7 />}
            />

            {/* End Injection Support */}

            {/* Living Well Center General */}

            <Route
              path="/living_well_center-1-1"
              element={<LivingWellCenter1_1 />}
            />

            <Route
              path="/living_well_center-1-2"
              element={<LivingWellCenter1_2 />}
            />

            <Route
              path="/living_well_center-1-3"
              element={<LivingWellCenter1_3 />}
            />

            <Route
              path="/living_well_center-1-5"
              element={<LivingWellCenter1_5 />}
            />

            <Route
              path="/living_well_center-2-1"
              element={<LivingWellCenter2_1 />}
            />

            <Route
              path="/living_well_center-2-2"
              element={<LivingWellCenter2_2 />}
            />

            <Route
              path="/living_well_center-2-3"
              element={<LivingWellCenter2_3 />}
            />

            <Route
              path="/living_well_center-2-4"
              element={<LivingWellCenter2_4 />}
            />

            <Route
              path="/living_well_center-3"
              element={<LivingWellCenter3 />}
            />

            <Route
              path="/living_well_center-4-1"
              element={<LivingWellCenter4_1 />}
            />

            <Route
              path="/living_well_center-4-2"
              element={<LivingWellCenter4_2 />}
            />

            <Route
              path="/living_well_center-4-3"
              element={<LivingWellCenter4_3 />}
            />

            <Route
              path="/living_well_center-4-4"
              element={<LivingWellCenter4_4 />}
            />

            <Route
              path="/living_well_center-4-5"
              element={<LivingWellCenter4_5 />}
            />

            <Route
              path="/living_well_center-6-1"
              element={<LivingWellCenter6_1 />}
            />

            <Route
              path="/living_well_center-6-1-long-covid"
              element={<LivingWellCenter_6_1_long_covid />}
            />

            <Route
                path="/living_well_center-6-2c-cancer-survivorship"
                element={<LivingWellCenter_6_2c_cancer_survivorship />}
            />

            <Route
              path="/living_well_center_general-6-2"
              element={<LivingWellCenter6_2 />}
            />

            <Route
              path="/living_well_center-6-3"
              element={<LivingWellCenter6_3 />}
            />

            <Route
              path="/living_well_center-6-4"
              element={<LivingWellCenter6_4 />}
            />

            <Route
              path="/living_well_center-6-5"
              element={<LivingWellCenter6_5 />}
            />

            <Route
              path="/living_well_center-9"
              element={<LivingWellCenter_9 />}
            />

            {/* End Living Well Center General */}

            {/* Siteman Pancreatic */}

            <Route
              path="/Pancreatic_2-0"
              element={<SitemanPancreaticAll2_0 />}
            />

            <Route
              path="/Pancreatic_2-1"
              element={<SitemanPancreaticAll2_1 />}
            />

            <Route
              path="/Pancreatic_2-2"
              element={<SitemanPancreaticAll2_2 />}
            />

            <Route
              path="/Pancreatic_2-3"
              element={<SitemanPancreaticAll2_3 />}
            />

            <Route
              path="/Pancreatic_2-3-1"
              element={<SitemanPancreaticAll2_3_1 />}
            />

            <Route path="/Pancreatic_1-1" element={<Content />} />

            <Route
              path="/Pancreatic_1-1-ramps"
              element={<SitemanPancreaticRamps1_1 />}
            />

            <Route path="/Pancreatic_1-2" element={<ContentPancreatic2 />} />
            <Route path="/Pancreatic_2-1-1" element={<ContentPancreatic3 />} />
            <Route path="/Pancreatic_2-1-2" element={<ContentPancreatic4 />} />
            <Route path="/Pancreatic_2-1-3" element={<ContentPancreatic5 />} />
            <Route path="/Pancreatic_2-2-1" element={<ContentPancreatic6 />} />
            <Route
              path="/Pancreatic_9-0"
              element={<SitemanPancreaticAll9_0 />}
            />
            <Route path="/Pancreatic_2-2-2" element={<ContentPancreatic7 />} />
            <Route
              path="/Pancreatic_2-3-1-1"
              element={<ContentPancreatic8 />}
            />
            <Route
              path="/Pancreatic_2-3-1-2"
              element={<ContentPancreatic9 />}
            />
            <Route
              path="/Pancreatic_2-3-1-3"
              element={<ContentPancreatic10 />}
            />
            <Route
              path="/Pancreatic_2-3-1-4"
              element={<ContentPancreatic11 />}
            />
            <Route
              path="/Pancreatic_2-3-1-5"
              element={<ContentPancreatic12 />}
            />
            <Route
              path="/Pancreatic_2-3-1-6"
              element={<ContentPancreatic13 />}
            />
            <Route
              path="/Pancreatic_2-3-1-7"
              element={<ContentPancreatic14 />}
            />
            <Route
              path="/Pancreatic_2-3-1-8"
              element={<ContentPancreatic15 />}
            />
            <Route
              path="/Pancreatic_2-3-2"
              element={<SitemanPancreaticAll2_3_2 />}
            />
            <Route
              path="/Pancreatic_2-3-3"
              element={<SitemanPancreaticAll2_3_3 />}
            />
            <Route path="/Pancreatic_3-1-1" element={<ContentPancreatic16 />} />
            <Route path="/Pancreatic_3-1-2" element={<ContentPancreatic17 />} />
            <Route path="/Pancreatic_3-1-3" element={<ContentPancreatic18 />} />
            <Route path="/Pancreatic_3-1-4" element={<ContentPancreatic19 />} />
            <Route path="/Pancreatic_3-1-5" element={<ContentPancreatic20 />} />
            <Route path="/Pancreatic_3-1-6" element={<ContentPancreatic21 />} />
            <Route path="/Pancreatic_3-2-1" element={<ContentPancreatic22 />} />
            <Route path="/Pancreatic_3-2-2" element={<ContentPancreatic23 />} />
            <Route path="/Pancreatic_3-2-3" element={<ContentPancreatic24 />} />
            <Route path="/Pancreatic_3-2-4" element={<ContentPancreatic25 />} />
            <Route path="/Pancreatic_3-2-5" element={<ContentPancreatic26 />} />
            <Route path="/Pancreatic_6" element={<ContentPancreatic27 />} />
            <Route path="/Pancreatic_7" element={<SitemanPancreaticAll9_0 />} />
            <Route
              path="/Pancreatic_7-1"
              element={<SitemanPancreaticAll7_1 />}
            />
            <Route
              path="/Pancreatic_7-3"
              element={<SitemanPancreaticAll7_3 />}
            />
            <Route
              path="/Pancreatic_7-4"
              element={<SitemanPancreaticAll7_4 />}
            />
            <Route
              path="/Pancreatic_7-5"
              element={<SitemanPancreaticAll7_5 />}
            />
            <Route path="/Pancreatic_9" element={<ContentPancreatic28 />} />
            <Route
              path="/Pancreatic_Video-WHIPPLE"
              element={<PancreaticVideoWhipple />}
            />
            <Route
              path="/Pancreatic_Video-RAMPS"
              element={<PancreaticVideoRAMPS />}
            />
            <Route
              path="/Pancreatic_Video-Getting-Ready-For-Surgery"
              element={<PancreaticVideoGettingReadyForSurgery />}
            />
            <Route
              path="/Pancreatic_Video-Having-A-Successful-Hospital-Stay"
              element={<PancreaticVideoHavingASuccessfulHospitalStay />}
            />
            <Route
              path="/Pancreatic_Video-Your-Therapy"
              element={<PancreaticVideoYourTherapy />}
            />
            <Route
              path="/Pancreatic_Video-Care-After-Surgery"
              element={<PancreaticVideoCareAfterSurgery />}
            />
            <Route
              path="/Pancreatic_Video-Care-Of-Your-Drain"
              element={<PancreaticVideoCareOfYourDrain />}
            />
            <Route
              path="/Pancreatic_Video-Going-Home-What-To-Expect"
              element={<PancreaticVideoGoingHomeWhatToExpect />}
            />
            <Route
              path="/Pancreatic_Video-Incentive-Spirometry"
              element={<PancreaticVideoIncentiveSpirometry />}
            />
            <Route
              path="/Pancreatic_Video_Page"
              element={<ContentPancreatic29 />}
            />
            <Route path="/LessonDisposal" element={<LessonDisposal />} />

            <Route
              path="/siteman-ent-tl-4-2-1"
              element={<SitemanENTTL4_2_1 />}
            />
            <Route
              path="/LessonDisposal/Introduction"
              element={<LessonDisposalIntroduction />}
            />
            <Route
              path="/LessonDisposal/WhatToTellYourDoctor"
              element={<LessonDisposalWhatToTellYourDoctor />}
            />
            <Route
              path="/LessonDisposal/WhatYouNeedToKnow"
              element={<LessonDisposalWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonDisposal/FAQResources"
              element={<LessonDisposalFAQResources />}
            />

            <Route
              path="/LessonDisposal/WhatToDoAndSafety"
              element={<LessonDisposalWhatToDoAndSafety />}
            />

            <Route
              path="/LessonFallPrevention"
              element={<LessonFallPrevention />}
            />

            <Route
              path="/LessonFallPrevention/Introduction"
              element={<LessonFallPreventionIntroduction />}
            />

            <Route
              path="/LessonFallPrevention/WhatToTellYourDoctor"
              element={<LessonFallPreventionWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonFallPrevention/WhatYouNeedToKnow"
              element={<LessonFallPreventionWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonFallPrevention/FAQResources"
              element={<LessonFallPreventionFAQResources />}
            />

            <Route
              path="/LessonFallPrevention/WhatToDoAndSafety"
              element={<LessonFallPreventionWhatToDoAndSafety />}
            />

            <Route path="/LessonInfection" element={<LessonInfection />} />
            <Route
              path="/LessonInfection/Introduction"
              element={<LessonInfectionIntroduction />}
            />
            <Route
              path="/LessonInfection/WhatToTellYourDoctor"
              element={<LessonInfectionWhatToTellYourDoctor />}
            />
            <Route
              path="/LessonInfection/WhatYouNeedToKnow"
              element={<LessonInfectionWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonInfection/FAQResources"
              element={<LessonInfectionFAQResources />}
            />

            <Route
              path="/LessonInfection/WhatToDoAndSafety"
              element={<LessonInfectionWhatToDoAndSafety />}
            />

            <Route path="/LessonMoving" element={<LessonMoving />} />

            <Route
              path="/LessonMoving/Introduction"
              element={<LessonMovingIntroduction />}
            />

            <Route
              path="/LessonMoving/WhatToTellYourDoctor"
              element={<LessonMovingWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonMoving/WhatYouNeedToKnow"
              element={<LessonMovingWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonMoving/FAQResources"
              element={<LessonMovingFAQResources />}
            />

            <Route
              path="/LessonMoving/WhatToDoAndSafety"
              element={<LessonMovingWhatToDoAndSafety />}
            />

            <Route path="/LessonWalkers" element={<LessonWalkers />} />

            <Route
              path="/LessonWalkers/Introduction"
              element={<LessonWalkersIntroduction />}
            />

            <Route
              path="/LessonWalkers/WhatToTellYourDoctor"
              element={<LessonWalkersWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonWalkers/WhatYouNeedToKnow"
              element={<LessonWalkersWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonWalkers/FAQResources"
              element={<LessonWalkersFAQResources />}
            />

            <Route
              path="/LessonWalkers/WhatToDoAndSafety"
              element={<LessonWalkersWhatToDoAndSafety />}
            />

            <Route path="/LessonWheelchairs" element={<LessonWheelchairs />} />

            <Route
              path="/LessonWheelchairs/Introduction"
              element={<LessonWheelchairsIntroduction />}
            />

            <Route
              path="/LessonWheelchairs/WhatToTellYourDoctor"
              element={<LessonWheelchairsWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonWheelchairs/WhatYouNeedToKnow"
              element={<LessonWheelchairsWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonWheelchairs/FAQResources"
              element={<LessonWheelchairsFAQResources />}
            />

            <Route
              path="/LessonWheelchairs/WhatToDoAndSafety"
              element={<LessonWheelchairsWhatToDoAndSafety />}
            />

            <Route path="/LessonWandering" element={<LessonWandering />} />

            <Route
              path="/LessonWandering/Introduction"
              element={<LessonWanderingIntroduction />}
            />

            <Route
              path="/LessonWandering/WhatToTellYourDoctor"
              element={<LessonWanderingWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonWandering/WhatYouNeedToKnow"
              element={<LessonWanderingWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonWandering/FAQResources"
              element={<LessonWanderingFAQResources />}
            />

            <Route
              path="/LessonWandering/WhatToDoAndSafety"
              element={<LessonWanderingWhatToDoAndSafety />}
            />

            <Route path="/LessonDressing" element={<LessonDressing />} />

            <Route
              path="/LessonDressing/Introduction"
              element={<LessonDressingIntroduction />}
            />

            <Route
              path="/LessonDressing/WhatToTellYourDoctor"
              element={<LessonDressingWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonDressing/WhatYouNeedToKnow"
              element={<LessonDressingWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonDressing/FAQResources"
              element={<LessonDressingFAQResources />}
            />

            <Route
              path="/LessonDressing/WhatToDoAndSafety"
              element={<LessonDressingWhatToDoAndSafety />}
            />

            <Route path="/LessonFootCare" element={<LessonFootCare />} />

            <Route
              path="/LessonFootCare/Introduction"
              element={<LessonFootCareIntroduction />}
            />

            <Route
              path="/LessonFootCare/WhatToTellYourDoctor"
              element={<LessonFootCareWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonFootCare/WhatYouNeedToKnow"
              element={<LessonFootCareWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonFootCare/FAQResources"
              element={<LessonFootCareFAQResources />}
            />

            <Route
              path="/LessonFootCare/WhatToDoAndSafety"
              element={<LessonFootCareWhatToDoAndSafety />}
            />

            <Route path="/LessonMouthCare" element={<LessonMouthCare />} />

            <Route
              path="/LessonMouthCare/Introduction"
              element={<LessonMouthCareIntroduction />}
            />

            <Route
              path="/LessonMouthCare/WhatToTellYourDoctor"
              element={<LessonMouthCareWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonMouthCare/WhatYouNeedToKnow"
              element={<LessonMouthCareWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonMouthCare/FAQResources"
              element={<LessonMouthCareFAQResources />}
            />

            <Route
              path="/LessonMouthCare/WhatToDoAndSafety"
              element={<LessonMouthCareWhatToDoAndSafety />}
            />

            <Route path="/LessonPain" element={<LessonPain />} />
            <Route
              path="/LessonPain/Introduction"
              element={<LessonPainIntroduction />}
            />

            <Route
              path="/LessonPain/WhatToTellYourDoctor"
              element={<LessonPainWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonPain/WhatYouNeedToKnow"
              element={<LessonPainWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonPain/FAQResources"
              element={<LessonPainFAQResources />}
            />

            <Route
              path="/LessonPain/WhatToDoAndSafety"
              element={<LessonPainWhatToDoAndSafety />}
            />

            <Route path="/LessonOxygen" element={<LessonOxygen />} />

            <Route
              path="/LessonOxygen/Introduction"
              element={<LessonOxygenIntroduction />}
            />

            <Route
              path="/LessonOxygen/WhatToTellYourDoctor"
              element={<LessonOxygenWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonOxygen/WhatYouNeedToKnow"
              element={<LessonOxygenWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonOxygen/FAQResources"
              element={<LessonOxygenFAQResources />}
            />

            <Route
              path="/LessonOxygen/WhatToDoAndSafety"
              element={<LessonOxygenWhatToDoAndSafety />}
            />

            <Route path="/LessonEnema" element={<LessonEnema />} />

            <Route
              path="/LessonEnema/Introduction"
              element={<LessonEnemaIntroduction />}
            />

            <Route
              path="/LessonEnema/WhatToTellYourDoctor"
              element={<LessonEnemaWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonEnema/WhatYouNeedToKnow"
              element={<LessonEnemaWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonEnema/FAQResources"
              element={<LessonEnemaFAQResources />}
            />

            <Route
              path="/LessonEnema/WhatToDoAndSafety"
              element={<LessonEnemaWhatToDoAndSafety />}
            />

            <Route path="/LessonObserving" element={<LessonObserving />} />

            <Route
              path="/LessonObserving/Introduction"
              element={<LessonObservingIntroduction />}
            />

            <Route
              path="/LessonObserving/WhatToTellYourDoctor"
              element={<LessonObservingWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonObserving/WhatYouNeedToKnow"
              element={<LessonObservingWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonObserving/FAQResources"
              element={<LessonObservingFAQResources />}
            />

            <Route
              path="/LessonObserving/WhatToDoAndSafety"
              element={<LessonObservingWhatToDoAndSafety />}
            />

            <Route
              path="/LessonBloodPressure"
              element={<LessonBloodPressure />}
            />

            <Route
              path="/LessonBloodPressure/Introduction"
              element={<LessonBloodPressureIntroduction />}
            />

            <Route
              path="/LessonBloodPressure/WhatToTellYourDoctor"
              element={<LessonBloodPressureWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonBloodPressure/WhatYouNeedToKnow"
              element={<LessonBloodPressureWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonBloodPressure/FAQResources"
              element={<LessonBloodPressureFAQResources />}
            />

            <Route
              path="/LessonBloodPressure/WhatToDoAndSafety"
              element={<LessonBloodPressureWhatToDoAndSafety />}
            />

            <Route path="/LessonPulse" element={<LessonPulse />} />

            <Route
              path="/LessonPulse/Introduction"
              element={<LessonPulseIntroduction />}
            />

            <Route
              path="/LessonPulse/WhatToTellYourDoctor"
              element={<LessonPulseWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonPulse/WhatYouNeedToKnow"
              element={<LessonPulseWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonPulse/FAQResources"
              element={<LessonPulseFAQResources />}
            />

            <Route
              path="/LessonPulse/WhatToDoAndSafety"
              element={<LessonPulseWhatToDoAndSafety />}
            />

            <Route path="/LessonTemperature" element={<LessonTemperature />} />

            <Route
              path="/LessonTemperature/Introduction"
              element={<LessonTemperatureIntroduction />}
            />

            <Route
              path="/LessonTemperature/WhatToTellYourDoctor"
              element={<LessonTemperatureWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonTemperature/WhatYouNeedToKnow"
              element={<LessonTemperatureWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonTemperature/FAQResources"
              element={<LessonTemperatureFAQResources />}
            />

            <Route
              path="/LessonTemperature/WhatToDoAndSafety"
              element={<LessonTemperatureWhatToDoAndSafety />}
            />

            <Route path="/LessonGlucose" element={<LessonGlucose />} />

            <Route
              path="/LessonGlucose/Introduction"
              element={<LessonGlucoseIntroduction />}
            />

            <Route
              path="/LessonGlucose/WhatToTellYourDoctor"
              element={<LessonGlucoseWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonGlucose/WhatYouNeedToKnow"
              element={<LessonGlucoseWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonGlucose/FAQResources"
              element={<LessonGlucoseFAQResources />}
            />

            <Route
              path="/LessonGlucose/WhatToDoAndSafety"
              element={<LessonGlucoseWhatToDoAndSafety />}
            />

            <Route
              path="/LessonGettingReady"
              element={<LessonGettingReady />}
            />

            <Route
              path="/LessonGettingReady/Introduction"
              element={<LessonGettingReadyIntroduction />}
            />

            <Route
              path="/LessonGettingReady/WhatToTellYourDoctor"
              element={<LessonGettingReadyWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonGettingReady/WhatYouNeedToKnow"
              element={<LessonGettingReadyWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonGettingReady/FAQResources"
              element={<LessonGettingReadyFAQResources />}
            />

            <Route
              path="/LessonGettingReady/WhatToDoAndSafety"
              element={<LessonGettingReadyWhatToDoAndSafety />}
            />

            <Route path="/LessonStockings" element={<LessonStockings />} />

            <Route
              path="/LessonStockings/Introduction"
              element={<LessonStockingsIntroduction />}
            />

            <Route
              path="/LessonStockings/WhatToTellYourDoctor"
              element={<LessonStockingsWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonStockings/WhatYouNeedToKnow"
              element={<LessonStockingsWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonStockings/FAQResources"
              element={<LessonStockingsFAQResources />}
            />

            <Route
              path="/LessonStockings/WhatToDoAndSafety"
              element={<LessonStockingsWhatToDoAndSafety />}
            />

            <Route
              path="/LessonAfterSurgery"
              element={<LessonAfterSurgery />}
            />

            <Route
              path="/LessonAfterSurgery/Introduction"
              element={<LessonAfterSurgeryIntroduction />}
            />

            <Route
              path="/LessonAfterSurgery/WhatToTellYourDoctor"
              element={<LessonAfterSurgeryWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonAfterSurgery/WhatYouNeedToKnow"
              element={<LessonAfterSurgeryWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonAfterSurgery/FAQResources"
              element={<LessonAfterSurgeryFAQResources />}
            />

            <Route
              path="/LessonAfterSurgery/WhatToDoAndSafety"
              element={<LessonAfterSurgeryWhatToDoAndSafety />}
            />

            <Route path="/LessonDrainage" element={<LessonDrainage />} />

            <Route
              path="/LessonDrainage/Introduction"
              element={<LessonDrainageIntroduction />}
            />

            <Route
              path="/LessonDrainage/WhatToTellYourDoctor"
              element={<LessonDrainageWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonDrainage/WhatYouNeedToKnow"
              element={<LessonDrainageWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonDrainage/FAQResources"
              element={<LessonDrainageFAQResources />}
            />

            <Route
              path="/LessonDrainage/WhatToDoAndSafety"
              element={<LessonDrainageWhatToDoAndSafety />}
            />

            <Route path="/LessonStitches" element={<LessonStitches />} />

            <Route
              path="/LessonStitches/Introduction"
              element={<LessonStitchesIntroduction />}
            />

            <Route
              path="/LessonStitches/WhatToTellYourDoctor"
              element={<LessonStitchesWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonStitches/WhatYouNeedToKnow"
              element={<LessonStitchesWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonStitches/FAQResources"
              element={<LessonStitchesFAQResources />}
            />

            <Route
              path="/LessonStitches/WhatToDoAndSafety"
              element={<LessonStitchesWhatToDoAndSafety />}
            />

            <Route
              path="/LessonGauzeDressing"
              element={<LessonGauzeDressing />}
            />

            <Route
              path="/LessonGauzeDressing/Introduction"
              element={<LessonGauzeDressingIntroduction />}
            />

            <Route
              path="/LessonGauzeDressing/WhatToTellYourDoctor"
              element={<LessonGauzeDressingWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonGauzeDressing/WhatYouNeedToKnow"
              element={<LessonGauzeDressingWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonGauzeDressing/FAQResources"
              element={<LessonGauzeDressingFAQResources />}
            />

            <Route
              path="/LessonGauzeDressing/WhatToDoAndSafety"
              element={<LessonGauzeDressingWhatToDoAndSafety />}
            />

            <Route
              path="/LessonGivingMedicines"
              element={<LessonGivingMedicines />}
            />

            <Route
              path="/LessonGivingMedicines/Introduction"
              element={<LessonGivingMedicinesIntroduction />}
            />

            <Route
              path="/LessonGivingMedicines/WhatToTellYourDoctor"
              element={<LessonGivingMedicinesWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonGivingMedicines/WhatYouNeedToKnow"
              element={<LessonGivingMedicinesWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonGivingMedicines/FAQResources"
              element={<LessonGivingMedicinesFAQResources />}
            />

            <Route
              path="/LessonGivingMedicines/WhatToDoAndSafety"
              element={<LessonGivingMedicinesWhatToDoAndSafety />}
            />

            <Route path="/LessonInhalers" element={<LessonInhalers />} />

            <Route
              path="/LessonInhalers/Introduction"
              element={<LessonInhalersIntroduction />}
            />

            <Route
              path="/LessonInhalers/WhatToTellYourDoctor"
              element={<LessonInhalersWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonInhalers/WhatYouNeedToKnow"
              element={<LessonInhalersWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonInhalers/FAQResources"
              element={<LessonInhalersFAQResources />}
            />

            <Route
              path="/LessonInhalers/WhatToDoAndSafety"
              element={<LessonInhalersWhatToDoAndSafety />}
            />

            <Route path="/LessonNebulizers" element={<LessonNebulizers />} />

            <Route
              path="/LessonNebulizers/Introduction"
              element={<LessonNebulizersIntroduction />}
            />

            <Route
              path="/LessonNebulizers/WhatToTellYourDoctor"
              element={<LessonNebulizersWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonNebulizers/WhatYouNeedToKnow"
              element={<LessonNebulizersWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonNebulizers/FAQResources"
              element={<LessonNebulizersFAQResources />}
            />

            <Route
              path="/LessonNebulizers/WhatToDoAndSafety"
              element={<LessonNebulizersWhatToDoAndSafety />}
            />

            <Route
              path="/LessonSuppositories"
              element={<LessonSuppositories />}
            />

            <Route
              path="/LessonSuppositories/Introduction"
              element={<LessonSuppositoriesIntroduction />}
            />

            <Route
              path="/LessonSuppositories/WhatToTellYourDoctor"
              element={<LessonSuppositoriesWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonSuppositories/WhatYouNeedToKnow"
              element={<LessonSuppositoriesWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonSuppositories/FAQResources"
              element={<LessonSuppositoriesFAQResources />}
            />

            <Route
              path="/LessonSuppositories/WhatToDoAndSafety"
              element={<LessonSuppositoriesWhatToDoAndSafety />}
            />

            <Route
              path="/LessonSubcutaneousInjections"
              element={<LessonSubcutaneousInjections />}
            />

            <Route
              path="/LessonSubcutaneousInjections/Introduction"
              element={<LessonSubcutaneousInjectionsIntroduction />}
            />

            <Route
              path="/LessonSubcutaneousInjections/WhatToTellYourDoctor"
              element={<LessonSubcutaneousInjectionsWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonSubcutaneousInjections/WhatYouNeedToKnow"
              element={<LessonSubcutaneousInjectionsWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonSubcutaneousInjections/FAQResources"
              element={<LessonSubcutaneousInjectionsFAQResources />}
            />

            <Route
              path="/LessonSubcutaneousInjections/WhatToDoAndSafety"
              element={<LessonSubcutaneousInjectionsWhatToDoAndSafety />}
            />

            <Route path="/LessonAspiration" element={<LessonAspiration />} />

            <Route
              path="/LessonAspiration/Introduction"
              element={<LessonAspirationIntroduction />}
            />

            <Route
              path="/LessonAspiration/WhatToTellYourDoctor"
              element={<LessonAspirationWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonAspiration/WhatYouNeedToKnow"
              element={<LessonAspirationWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonAspiration/FAQResources"
              element={<LessonAspirationFAQResources />}
            />

            <Route
              path="/LessonAspiration/WhatToDoAndSafety"
              element={<LessonAspirationWhatToDoAndSafety />}
            />

            <Route
              path="/LessonFeedingAPerson"
              element={<LessonFeedingAPerson />}
            />

            <Route
              path="/LessonFeedingAPerson/Introduction"
              element={<LessonFeedingAPersonIntroduction />}
            />

            <Route
              path="/LessonFeedingAPerson/WhatToTellYourDoctor"
              element={<LessonFeedingAPersonWhatToTellYourDoctor />}
            />

            <Route
              path="/LessonFeedingAPerson/WhatYouNeedToKnow"
              element={<LessonFeedingAPersonWhatYouNeedToKnow />}
            />

            <Route
              path="/LessonFeedingAPerson/FAQResources"
              element={<LessonFeedingAPersonFAQResources />}
            />

            <Route
              path="/LessonFeedingAPerson/WhatToDoAndSafety"
              element={<LessonFeedingAPersonWhatToDoAndSafety />}
            />

            <Route path="/Lessons" element={<Lessons />} />

            {/* End Siteman Pancreatic */}

            {/* Siteman ENT */}

            <Route path="/homeCluster" element={<HomeCluster />} />

            <Route path="/siteman-ent-all-1-0" element={<SitemanEntAll1_0 />} />

            <Route path="/siteman-ent-all-2-0" element={<SitemanEntAll2_0 />} />

            <Route path="/siteman-ent-all-3-0" element={<SitemanEntAll3_0 />} />

            <Route path="/siteman-ent-all-4-0" element={<SitemanEntAll4_0 />} />

            <Route
              path="/siteman-ent-all-4-1A"
              element={<SitemanEntAll4_1A />}
            />

            <Route
              path="/siteman-ent-all-4-1B"
              element={<SitemanEntAll4_1B />}
            />

            <Route
              path="/siteman-ent-all-4-1C"
              element={<SitemanEntAll4_1C />}
            />

            <Route path="/siteman-ent-all-4-2" element={<SitemanEntAll4_2 />} />

            <Route
              path="/siteman-ent-all-4-1-2"
              element={<SitemanENTALL4_1_2 />}
            />

            <Route path="/siteman-ent-all-7-0" element={<SitemanEntAll7_0 />} />

            <Route path="/siteman-ent-all-8-0" element={<SitemanEntAll8_0 />} />

            <Route path="/siteman-ent-all-9-0" element={<SitemanEntAll9_0 />} />

            <Route path="/siteman-ent-all-1-2" element={<SitemanENTAll1_2 />} />

            <Route path="/siteman-ent-all-1-3" element={<SitemanENTAll1_3 />} />

            <Route path="/siteman-ent-all-1-4" element={<SitemanENTAll1_4 />} />

            <Route path="/siteman-ent-all-1-5" element={<SitemanENTAll1_5 />} />

            <Route
              path="/siteman-ent-tgsp-1-6"
              element={<SitemanENTTGSP1_6 />}
            />

            <Route path="/siteman-ent-tl-1-6" element={<SitemanENTTL1_6 />} />

            <Route path="/siteman-ent-all-2-4" element={<SitemanENTAll2_4 />} />

            <Route path="/siteman-ent-all-3-6" element={<SitemanENTAll3_6 />} />

            <Route path="/siteman_ent_all-3-7" element={<SitemanENTAll3_7 />} />

            <Route
              path="/siteman-ent-all-4-1-3"
              element={<SitemanENTAll4_1_3 />}
            />

            <Route
              path="/siteman-ent-all-4-1-4"
              element={<SitemanENTAll4_1_4 />}
            />

            <Route
              path="/siteman-ent-all-4-2-2"
              element={<SitemanENTAll4_2_2 />}
            />

            <Route
              path="/siteman-ent-all-4-2-3"
              element={<SitemanENTAll4_2_3 />}
            />

            <Route
              path="/siteman-ent-all-4-2-4"
              element={<SitemanENTAll4_2_4 />}
            />

            <Route
              path="/siteman-ent-all-4-2-5"
              element={<SitemanENTAll4_2_5 />}
            />

            <Route
              path="/siteman-ent-all-4-2-7"
              element={<SitemanENTAll4_2_7 />}
            />

            <Route
              path="/siteman-ent-all-4-2-9"
              element={<SitemanENTAll4_2_9 />}
            />

            <Route path="/siteman-ent-all-4-4" element={<SitemanENTAll4_4 />} />

            <Route path="/siteman-ent-all-4-6" element={<SitemanENTAll4_6 />} />

            <Route path="/siteman-ent-all-4-7" element={<SitemanENTAll4_7 />} />

            <Route path="/siteman-ent-all-7-3" element={<SitemanENTAll7_3 />} />

            <Route path="/siteman-ent-all-7-5" element={<SitemanENTAll7_5 />} />

            <Route path="/siteman-ent-all-7-6" element={<SitemanENTAll7_6 />} />

            <Route path="/siteman-ent-all-8-1" element={<SitemanENTAll8_1 />} />

            <Route path="/siteman-ent-all-8-2" element={<SitemanENTAll8_2 />} />

            <Route path="/siteman-ent-all-8-3" element={<SitemanENTAll8_3 />} />

            <Route path="/siteman-ent-all-8-5" element={<SitemanENTAll8_5 />} />

            <Route path="/siteman-ent-all-8-6" element={<SitemanENTAll8_6 />} />

            <Route path="/siteman-ent-all-8-7" element={<SitemanENTAll8_7 />} />

            <Route path="/siteman-ent-all-8-8" element={<SitemanENTAll8_8 />} />

            <Route path="/siteman-ent-all-8-9" element={<SitemanENTAll8_9 />} />

            <Route path="/siteman-ent-all-9-1" element={<SitemanENTAll9_1 />} />

            <Route path="/siteman-ent-all-9-3" element={<SitemanENTAll9_3 />} />

            <Route path="/siteman-ent-all-9-4" element={<SitemanENTAll9_4 />} />

            <Route path="/siteman-ent-all-9-5" element={<SitemanENTAll9_5 />} />

            <Route path="/siteman-ent-all-9-6" element={<SitemanENTAll9_6 />} />

            <Route
              path="/siteman-ent-tgsp-1-1"
              element={<SitemanENTTGSP1_1 />}
            />

            <Route
              path="/siteman-ent-tgsp-1-3"
              element={<SitemanENTTGSP1_3 />}
            />

            <Route
              path="/siteman-ent-tgsp-1-4"
              element={<SitemanENTTGSP1_4 />}
            />

            <Route
              path="/siteman-ent-tgsp-1-5"
              element={<SitemanENTTGSP1_5 />}
            />

            <Route
              path="/siteman-ent-tgsp-2-1"
              element={<SitemanENTTGSP2_1 />}
            />

            <Route
              path="/siteman-ent-tgsp-2-2"
              element={<SitemanENTTGSP2_2 />}
            />

            <Route
              path="/siteman-ent-tgsp-2-3"
              element={<SitemanENTTGSP2_3 />}
            />

            <Route
              path="/siteman-ent-tgsp-2-3-1"
              element={<SitemanENTTGSP2_3_1 />}
            />

            <Route
              path="/siteman-ent-tgsp-2-3-2"
              element={<SitemanENTTGSP2_3_2 />}
            />

            <Route
              path="/siteman-ent-tgsp-2-3-3"
              element={<SitemanENTTGSP2_3_3 />}
            />

            <Route
              path="/siteman-ent-tgsp-2-3-4"
              element={<SitemanENTTGSP2_3_4 />}
            />

            <Route
              path="/siteman-ent-tgsp-2-5"
              element={<SitemanENTTGSP2_5 />}
            />

            <Route
              path="/siteman-ent-tgsp-2-6"
              element={<SitemanENTTGSP2_6 />}
            />

            <Route
              path="/siteman-ent-tgsp-3-1"
              element={<SitemanENTTGSP3_1 />}
            />

            <Route
              path="/siteman-ent-tgsp-3-6"
              element={<SitemanENTTGSP3_6 />}
            />

            <Route
              path="/siteman-ent-tgsp-4-1-1"
              element={<SitemanENTTGSP4_1_1 />}
            />

            <Route
              path="/siteman-ent-tgsp-4-1-5"
              element={<SitemanENTTGSP4_1_5 />}
            />

            <Route
              path="/siteman-ent-tgsp-4-1-6"
              element={<SitemanENTTGSP4_1_6 />}
            />
            <Route
              path="/siteman-ent-tgsp-4-2-1"
              element={<SitemanENTTGSP4_2_1 />}
            />

            <Route
              path="/siteman-ent-tgsp-4-2-4"
              element={<SitemanENTTGSP4_2_4 />}
            />

            <Route
              path="/siteman-ent-tgsp-4-3"
              element={<SitemanENTTGSP4_3 />}
            />

            <Route
              path="/siteman-ent-tgsp-4-5"
              element={<SitemanENTTGSP4_5 />}
            />

            <Route
              path="/siteman-ent-tgsp-6-0"
              element={<SitemanENTTGSP6_0 />}
            />

            <Route
              path="/siteman-ent-tgsp-7-1"
              element={<SitemanENTTGSP7_1 />}
            />

            <Route
              path="/siteman-ent-tgsp-7-2"
              element={<SitemanENTTGSP7_2 />}
            />

            <Route
              path="/siteman-ent-tgsp-7-4"
              element={<SitemanENTTGSP7_4 />}
            />

            <Route path="/siteman-ent-tl-1-1" element={<SitemanENTTL1_1 />} />

            <Route path="/siteman-ent-tl-1-3" element={<SitemanENTTL1_3 />} />

            <Route path="/siteman-ent-tl-1-4" element={<SitemanENTTL1_4 />} />

            <Route path="/siteman-ent-tl-1-5" element={<SitemanENTTL1_5 />} />

            <Route path="/siteman-ent-tl-2-1" element={<SitemanENTTL2_1 />} />

            <Route path="/siteman-ent-tl-2-2" element={<SitemanENTTL2_2 />} />

            <Route path="/siteman-ent-tl-2-3" element={<SitemanENTTL2_3 />} />

            <Route path="/siteman-ent-tl-2-4" element={<SitemanENTTL2_4 />} />

            <Route path="/siteman-ent-tl-2-5" element={<SitemanENTTL2_5 />} />

            <Route path="/siteman-ent-tl-2-6" element={<SitemanENTTL2_6 />} />

            <Route path="/siteman-ent-tl-3-1" element={<SitemanENTTL3_1 />} />

            <Route path="/siteman-ent-all-3-2" element={<SitemanENTTL3_2 />} />

            <Route path="/siteman-ent-tl-3-6" element={<SitemanENTTL3_6 />} />

            <Route
              path="/siteman-ent-tl-4-1-1"
              element={<SitemanENTTL4_1_1 />}
            />

            <Route
              path="/siteman-ent-tl-4-1-5"
              element={<SitemanENTTL4_1_5 />}
            />

            <Route
              path="/siteman-ent-tl-4-1-6"
              element={<SitemanENTTL4_1_6 />}
            />

            <Route
              path="/siteman-ent-tl-4-2-1"
              element={<SitemanENTTL4_2_1 />}
            />

            <Route
              path="/siteman-ent-tl-4-2-4"
              element={<SitemanENTTL4_2_4 />}
            />

            <Route path="/siteman-ent-tl-4-3" element={<SitemanENTTL4_3 />} />

            <Route path="/siteman-ent-tl-4-5" element={<SitemanENTTL4_5 />} />

            <Route path="/siteman-ent-tl-6-0" element={<SitemanENTTL6_0 />} />

            <Route path="/siteman-ent-tl-7-1" element={<SitemanENTTL7_1 />} />

            <Route path="/siteman-ent-tl-7-2" element={<SitemanENTTL7_2 />} />

            <Route path="/siteman-ent-tl-7-4" element={<SitemanENTTL7_4 />} />

            <Route path="/siteman-ent-tl-7-8" element={<SitemanENTTL7_8 />} />

            {/* End Siteman ENT */}

            {/* Pritikin */}

            <Route path="/pritikin-1-1" element={<Pritikin1_1 />} />

            <Route path="/pritikin-1-2" element={<Pritikin1_2 />} />

            <Route path="/pritikin-1-3" element={<Pritikin1_3 />} />

            <Route path="/pritikin-1-4" element={<Pritikin1_4 />} />

            <Route path="/pritikin-2-1" element={<Pritikin2_1 />} />

            <Route path="/pritikin-2-2" element={<Pritikin2_2 />} />

            <Route path="/pritikin-2-3" element={<Pritikin2_3 />} />

            <Route path="/pritikin-2-4" element={<Pritikin2_4 />} />

            <Route path="/pritikin-2-5" element={<Pritikin2_5 />} />

            <Route path="/pritikin-3-0" element={<Pritikin3_0 />} />

            <Route path="/pritikin-4-1" element={<Pritikin4_1 />} />

            <Route path="/pritikin-4-2" element={<Pritikin4_2 />} />

            <Route
              path="/unity-point-pritikin-4-2"
              element={<UnityPointPritikin4_2 />}
            />

            <Route path="/chi-pritikin-4-2" element={<ChiPritikin4_2 />} />

            <Route
              path="/boone-health-pritikin-4-2"
              element={<BOONE_HEALTH_Pritikin4_2 />}
            />

            <Route path="/pritikin-4-3" element={<Pritikin4_3 />} />

            <Route path="/pritikin-4-4" element={<Pritikin4_4 />} />

            <Route path="/pritikin-6-0" element={<Pritikin6_0 />} />

            <Route path="/pritikin-7-1" element={<Pritikin7_1 />} />

            <Route path="/pritikin-7-2" element={<Pritikin7_2 />} />

            <Route path="/chi-pritikin-7-2" element={<Pritikin_CHI_7_2 />} />

            <Route
              path="/boone-health-pritikin-7-2"
              element={<Pritikin_BOONE_HEALTH_7_2 />}
            />

            <Route
              path="/boone-health-pritikin-7-3"
              element={<Pritikin_BOONE_HEALTH_7_3 />}
            />

            <Route path="/pritikin-7-4" element={<Pritikin7_4 />} />

            <Route path="/pritikin-9-0" element={<Pritikin9_0 />} />

            {/* End Pritikin */}

            {/* Common Pages */}

            <Route
              path="/siteman-page-not-completed"
              element={<SitemanENTNotCompleted />}
            />

            <Route path="/terms-of-use" element={<TermsOfUse />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/cookie-policy" element={<CookiePolicy />} />

            <Route path="/clusterDemo" element={<ClusterDemo />} />
            <Route path="/demo" element={<Demo />} />

            <Route path="/content-page" element={<ContentPage />} />

            {/* End Common Pages */}
          </Route>
        </Routes>
        <BackToTop
          showOnScrollDown
          showAt={100}
          speed={1500}
          easing="easeInOutQuint"
          className="backToTopMobile"
        >
          <FontAwesomeIcon icon="fa-solid fa-circle-chevron-up" />
        </BackToTop>
        <AddToHomeScreen />
      </AppContextProvider>
    </Fragment>
  );
}

export default App;
