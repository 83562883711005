import React from "react";
import { CodeBlock } from "react-code-block";
import { useCopyToClipboard } from "react-use";
import "@fortawesome/fontawesome-svg-core/styles.css";
import YouTube from "react-youtube";

function LegoL99Component({ code, language }) {
  code = ` {/* L99 Resource and Description New */}
                                    <div className="lego l099 legoMargin3">
                                        <div className="legoContainer">
                                            <div className="imgContainer">
                                                <a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Sleep+23.pdf">
                                                    <img
                                                        className=""
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/68b21eb76f767424a65af92427d41025.jpeg"
                                                    />
                                                </a>
                                            </div>
                                            <div className="contentContainer">
                                                <p className="descriptionHeader">Lifestyle Sleep Health Guide</p>
                                                <p>As an extremely important part of human health, it’s key to
                                                    understand what Lifestyle Sleep Health is all about. Find out more
                                                    on what can disrupt sleep, tips for better sleep, how to set
                                                    successful sleep goals and sleep recommendations based on your
                                                    age.</p>
                                                <p>Click image or <a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LM+Sleep+23.pdf">this link</a> to view.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L99 Resource and Description New */}`;
  language = "jsx";
  const [state, copyToClipboard] = useCopyToClipboard();

  const copyCode = () => {
    // Logic to copy `code`
    copyToClipboard(code);
  };

  return (
    <CodeBlock code={code} language={language}>
      <div className="relative">
        <CodeBlock.Code className="bg-black">
          <div className="table-row">
            <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
            <CodeBlock.LineContent className="table-cell">
              <CodeBlock.Token />
            </CodeBlock.LineContent>
          </div>
        </CodeBlock.Code>
        <div className="buttonContainer">
          <button className="btn btn-primary ml-auto" onClick={copyCode}>
            <i className="fa-solid fa-copy"></i>{" "}
            {state.value ? "Copied!" : "Copy code"}
          </button>
        </div>
      </div>
    </CodeBlock>
  );
}

export default LegoL99Component;
