import React from "react";
import Container from "react-bootstrap/Container";
import "@fortawesome/fontawesome-svg-core/styles.css";
import YouTube from "react-youtube";
import LegoL1Component from "./legoComponents/legoL1Component";
import LegoL2Component from "./legoComponents/legoL2Component";
import LegoL3Component from "./legoComponents/legoL3Component";
import LegoL3AComponent from "./legoComponents/legoL3AComponent";
import LegoL4Component from "./legoComponents/legoL4Component";
import LegoL4AComponent from "./legoComponents/LegoL4AComponent";
import LegoL5Component from "./legoComponents/legoL5Component";
import LegoL6Component from "./legoComponents/legoL6Component";
import LegoL10AComponent from "./legoComponents/legoL10AComponent";
import LegoL11Component from "./legoComponents/legoL11Component";
import LegoL14Component from "./legoComponents/legoL14Component";
import LegoL28Component from "./legoComponents/legoL28Component";
import LegoL31Component from "./legoComponents/legoL31Component";
import LegoL53Component from "./legoComponents/legoL53Component";
import LegoL66Component from "./legoComponents/legoL66Component";
import LegoL82Component from "./legoComponents/legoL82Component";
import LegoL83Component from "./legoComponents/legoL83Component";
import LegoL84Component from "./legoComponents/legoL84Component";
import LegoL85Component from "./legoComponents/legoL85Component";
import LegoL86Component from "./legoComponents/legoL86Component";
import L1ComponentCode from "./legoComponentCode/L1ComponentCode";
import L2ComponentCode from "./legoComponentCode/L2ComponentCode";
import L3ComponentCode from "./legoComponentCode/L3ComponentCode";
import L3AComponentCode from "./legoComponentCode/L3AComponentCode";
import L4ComponentCode from "./legoComponentCode/L4ComponentCode";
import L4AComponentCode from "./legoComponentCode/L4AComponentCode";
import L5ComponentCode from "./legoComponentCode/L5ComponentCode";
import L6ComponentCode from "./legoComponentCode/L6ComponentCode";
import L10AComponentCode from "./legoComponentCode/L10AComponentCode";
import L11ComponentCode from "./legoComponentCode/L11ComponentCode";
import L14ComponentCode from "./legoComponentCode/L14ComponentCode";
import L28ComponentCode from "./legoComponentCode/L28ComponentCode";
import L31ComponentCode from "./legoComponentCode/L31ComponentCode";
import L53ComponentCode from "./legoComponentCode/L53ComponentCode";
import L66ComponentCode from "./legoComponentCode/L66ComponentCode";
import L82ComponentCode from "./legoComponentCode/L82ComponentCode";
import L83ComponentCode from "./legoComponentCode/L83ComponentCode";
import L84ComponentCode from "./legoComponentCode/L84ComponentCode";
import L85ComponentCode from "./legoComponentCode/L85ComponentCode";
import L86ComponentCode from "./legoComponentCode/L86ComponentCode";
import L1AComponentCode from "./legoComponentCode/L1AComponentCode";
import LegoL1AComponent from "./legoComponents/legoL1AComponent";
import L1BComponentCode from "./legoComponentCode/L1BComponentCode";
import LegoL1BComponent from "./legoComponents/legoL1BComponent";
import L14AComponentCode from "./legoComponentCode/L14AComponentCode";
import LegoL14AComponent from "./legoComponents/legoL14AComponent";
import L14BComponentCode from "./legoComponentCode/L14BComponentCode";
import LegoL14BComponent from "./legoComponents/legoL14BComponent";
import L83AComponentCode from "./legoComponentCode/L83AComponentCode";
import LegoL83AComponent from "./legoComponents/legoL83AComponent";
import L83BComponentCode from "./legoComponentCode/L83BComponentCode";
import LegoL83BComponent from "./legoComponents/legoL83BComponent";
import L83CComponentCode from "./legoComponentCode/L83CComponentCode";
import LegoL83CComponent from "./legoComponents/legoL83CComponent";
import L83DComponentCode from "./legoComponentCode/L83DComponentCode";
import LegoL83DComponent from "./legoComponents/legoL83DComponent";
import L83EComponentCode from "./legoComponentCode/L83EComponentCode";
import LegoL83EComponent from "./legoComponents/legoL83EComponent";
import L83FComponentCode from "./legoComponentCode/L83FComponentCode";
import LegoL83FComponent from "./legoComponents/legoL83FComponent";
import L83GComponentCode from "./legoComponentCode/L83GComponentCode";
import LegoL83GComponent from "./legoComponents/legoL83GComponent";
import L83HComponentCode from "./legoComponentCode/L83HComponentCode";
import LegoL83HComponent from "./legoComponents/legoL83HComponent";
import L83IComponentCode from "./legoComponentCode/L83IComponentCode";
import LegoL83IComponent from "./legoComponents/legoL83IComponent";
import L83JComponentCode from "./legoComponentCode/L83JComponentCode";
import LegoL83JComponent from "./legoComponents/legoL83JComponent";
import LegoL87Component from "./legoComponents/legoL87Component";
import L87ComponentCode from "./legoComponentCode/L87ComponentCode";
import L83KComponentCode from "./legoComponentCode/L83KComponentCode";
import LegoL83KComponent from "./legoComponents/legoL83KComponent";
import L83LComponentCode from "./legoComponentCode/L83LComponentCode";
import LegoL83LComponent from "./legoComponents/legoL83LComponent";
import L101ComponentCode from "./legoComponentCode/L101ComponentCode";
import LegoL101Component from "./legoComponents/legoL101Component";
import L53AComponentCode from "./legoComponentCode/L53AComponentCode";
import L53BComponentCode from "./legoComponentCode/L53BComponentCode";
import LegoL53AComponent from "./legoComponents/legoL53AComponent";
import LegoL53BComponent from "./legoComponents/legoL53BComponent";
import L88ComponentCode from "./legoComponentCode/L88ComponentCode";
import LegoL88Component from "./legoComponents/legoL88Component";
import L88AComponentCode from "./legoComponentCode/L88AComponentCode";
import LegoL88AComponent from "./legoComponents/legoL88AComponent";
import L88BComponentCode from "./legoComponentCode/L88BComponentCode";
import LegoL88BComponent from "./legoComponents/legoL88BComponent";
import L89ComponentCode from "./legoComponentCode/L89ComponentCode";
import LegoL89Component from "./legoComponents/legoL89Component";
import L89AComponentCode from "./legoComponentCode/L89AComponentCode";
import LegoL89AComponent from "./legoComponents/legoL89AComponent";
import L96ComponentCode from "./legoComponentCode/L96ComponentCode";
import LegoL96Component from "./legoComponents/legoL96Component";
import L98ComponentCode from "./legoComponentCode/L98ComponentCode";
import LegoL98Component from "./legoComponents/LegoL98Component";
import L90ComponentCode from "./legoComponentCode/L90ComponentCode";
import LegoL90Component from "./legoComponents/legoL90Component";
import L91ComponentCode from "./legoComponentCode/L91ComponentCode";
import LegoL91Component from "./legoComponents/legoL91Component";
import L92ComponentCode from "./legoComponentCode/L92ComponentCode";
import LegoL92Component from "./legoComponents/legoL92Component";
import L11AComponentCode from "./legoComponentCode/L11AComponentCode";
import LegoL11AComponent from "./legoComponents/legoL11AComponent";
import L11BComponentCode from "./legoComponentCode/L11BComponentCode";
import LegoL11BComponent from "./legoComponents/legoL11BComponent";
import L14CComponentCode from "./legoComponentCode/L14CComponentCode";
import LegoL14CComponent from "./legoComponents/legoL14CComponent";
import L14DComponentCode from "./legoComponentCode/L14DComponentCode";
import LegoL14DComponent from "./legoComponents/legoL14DComponent";
import L93ComponentCode from "./legoComponentCode/L93ComponentCode";
import LegoL93Component from "./legoComponents/legoL93Component";
import L12ComponentCode from "./legoComponentCode/L12ComponentCode";
import LegoL12Component from "./legoComponents/legoL12Component";
import LegoL12AComponent from "./legoComponents/legoL12AComponent";
import L12AComponentCode from "./legoComponentCode/L12AComponentCode";
import LegoL12BComponent from "./legoComponents/legoL12BComponent";
import L12BComponentCode from "./legoComponentCode/L12BComponentCode";
import LegoL95Component from "./legoComponents/legoL95Component";
import L95ComponentCode from "./legoComponentCode/L95ComponentCode";
import L99ComponentCode from "./legoComponentCode/L99ComponentCode";
import LegoL99Component from "./legoComponents/legoL99Component";
import L99AComponentCode from "./legoComponentCode/L99AComponentCode";
import LegoL99AComponent from "./legoComponents/legoL99AComponent";
import L99BComponentCode from "./legoComponentCode/L99BComponentCode";
import LegoL99BComponent from "./legoComponents/legoL99BComponent";
import L99CComponentCode from "./legoComponentCode/L99CComponentCode";
import LegoL99CComponent from "./legoComponents/legoL99CComponent";

const LegosRefactored = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-10 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  <h1 className="legoCatalogHeader">Legos Refactored 2024</h1>
                  <div className="card">
                    <div className="card-header">
                      <label>L1 Tile Title Header</label>
                    </div>
                    <div className="card-body">
                      {/* L1 Tile Title Header */}
                      <L1ComponentCode />
                      {/* End Tile Title Header L1 */}
                      <LegoL1Component />

                      {/* L1A Tile Title Header */}
                      <L1AComponentCode />
                      {/* End Tile Title Header L1A */}
                      <LegoL1AComponent />

                      {/* L1A Tile Title Header */}
                      <L1BComponentCode />
                      {/* End Tile Title Header L1A */}
                      <LegoL1BComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L2 Headline</label>
                    </div>
                    <div className="card-body">
                      {/* L2 */}
                      <L2ComponentCode />
                      {/* End L2 */}
                      <LegoL2Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L3 Subhead Upper and Lower</label>
                    </div>
                    <div className="card-body">
                      {/* L3 Subhead Upper and Lower */}
                      <L3ComponentCode />
                      {/* End L3 Subhead Upper and Lower */}
                      <LegoL3Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L3A Capitalized Subhead Italic</label>
                    </div>
                    <div className="card-body">
                      {/* L3 Subhead Upper and Lower */}
                      <L3AComponentCode />
                      {/* End L3 Subhead Upper and Lower */}
                      <LegoL3AComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L4 Subhead Upper Case</label>
                    </div>
                    <div className="card-body">
                      {/* L4 Subhead Upper */}
                      <L4ComponentCode />
                      {/* End L4 Subhead Upper */}
                      <LegoL4Component />
                    </div>
                    <div className="card-header">
                      <label>L4 Subhead Upper Case</label>
                    </div>
                    <div className="card-body">
                      {/* L4 Subhead Upper */}
                      <L4AComponentCode />
                      {/* End L4 Subhead Upper */}
                      <LegoL4AComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L5 Subhead Color Bar</label>
                    </div>
                    <div className="card-body">
                      {/* L5 Subhead Color Bar */}
                      <L5ComponentCode />
                      {/* End L5 Subhead Color Bar */}
                      <LegoL5Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L6 Background image header bar</label>
                    </div>
                    <div className="card-body">
                      {/* L6 Background image header bar */}
                      <L6ComponentCode />
                      {/* End L6 Background image header bar */}
                      <LegoL6Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L10A Bold Lead In</label>
                    </div>
                    <div className="card-body">
                      {/* L10A Bold Lead In */}
                      <L10AComponentCode />
                      {/* End L10A Bold Lead In */}
                      <LegoL10AComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L11 Image</label>
                    </div>
                    <div className="card-body">
                      {/* L11 Image */}
                      <L11ComponentCode />
                      {/* End L11 Image */}
                      <LegoL11Component />

                      {/* L11A Image */}
                      <L11AComponentCode />
                      {/* End L11A Image */}
                      <LegoL11AComponent />

                      {/* L11B Image */}
                      <L11BComponentCode />
                      {/* End L11B Image */}
                      <LegoL11BComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>
                        L12 Head, Lead In Photo Small with body copy
                      </label>
                    </div>
                    <div className="card-body">
                      {/* L12 Head, Lead In Photo Small with body copy */}
                      <L12ComponentCode />
                      {/* End L12 Head, Lead In Photo Small with body copy */}
                      <LegoL12Component />

                      {/* L12A Head, Lead In Photo Larger with body copy */}
                      <L12AComponentCode />
                      {/* End L12A Head, Lead In Photo Larger with body copy */}
                      <LegoL12AComponent />
                      {/* L12A Head, Lead In Photo Larger with body copy */}
                      <L12BComponentCode />
                      {/* End L12A Head, Lead In Photo Larger with body copy */}
                      <LegoL12BComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L14 Paragraph</label>
                    </div>
                    <div className="card-body">
                      {/* L14 Paragraph */}
                      <L14ComponentCode />
                      {/* End L14 Paragraph */}
                      <LegoL14Component />

                      {/* L14A Paragraph With Bold Spans */}
                      <L14AComponentCode />
                      {/* End L14A Paragraph With Bold Spans */}
                      <LegoL14AComponent />

                      {/* L14B Paragraph With Bold Italic Spans */}
                      <L14BComponentCode />
                      {/* End LParagraph With Bold Italic Spans */}
                      <LegoL14BComponent />

                      {/* L14C Paragraph Phone Number Link */}
                      <L14CComponentCode />
                      {/* End L14 Paragraph Phone Number Link */}
                      <LegoL14CComponent />

                      {/* L14D Paragraph External Link */}
                      <L14DComponentCode />
                      {/* End L14D Paragraph External Link */}
                      <LegoL14DComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L28 Back To Top</label>
                    </div>
                    <div className="card-body">
                      {/* L28 Back To Top */}
                      <L28ComponentCode />
                      {/* End L28 Back To Top */}
                      <LegoL28Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L31 Video Box Large</label>
                    </div>
                    <div className="card-body">
                      {/* L31 Video Box Large */}
                      <L31ComponentCode />
                      {/* End L31 Video Box Large */}
                      <LegoL31Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L53 Bullet List</label>
                    </div>
                    <div className="card-body">
                      {/* L53 Bullet List */}
                      <L53ComponentCode />
                      {/* End L53 Bullet List */}
                      <LegoL53Component />

                      {/* L53A Bullet List */}
                      <L53AComponentCode />
                      {/* End L53A Bullet List */}
                      <LegoL53AComponent />

                      {/* L53A Bullet List */}
                      <L53BComponentCode />
                      {/* End L53A Bullet List */}
                      <LegoL53BComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L66 Divider Rule</label>
                    </div>
                    <div className="card-body">
                      {/* L66 Divider Rule */}
                      <L66ComponentCode />
                      {/* End L66 Divider Rule */}
                      <LegoL66Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L82? Content 2 sides 50% width</label>
                    </div>
                    <div className="card-body">
                      {/* L82? Content 2 sides 50% width */}
                      <L82ComponentCode />
                      {/* End L82? Content 2 sides 50% width */}
                      <LegoL82Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L83 Icon Color Bar New</label>
                    </div>
                    <div className="card-body">
                      {/* L83 Icon Color Bar New */}
                      <L83ComponentCode />
                      {/* End L83 Icon Color Bar New */}
                      <LegoL83Component />

                      {/* L83A Icon Color Bar New */}
                      <L83AComponentCode />
                      {/* End L83A Icon Color Bar New */}
                      <LegoL83AComponent />

                      {/* L83B Icon Color Bar New */}
                      <L83BComponentCode />
                      {/* End L83B Icon Color Bar New */}
                      <LegoL83BComponent />

                      {/* L83C Icon Color Bar New */}
                      <L83CComponentCode />
                      {/* End L83C Icon Color Bar New */}
                      <LegoL83CComponent />

                      {/* L83D Icon Color Bar New */}
                      <L83DComponentCode />
                      {/* End L83D Icon Color Bar New */}
                      <LegoL83DComponent />

                      {/* L83E Icon Color Bar New */}
                      <L83EComponentCode />
                      {/* End L83E Icon Color Bar New */}
                      <LegoL83EComponent />

                      {/* L83F Icon Color Bar New */}
                      <L83FComponentCode />
                      {/* End L83F Icon Color Bar New */}
                      <LegoL83FComponent />

                      {/* L83G Icon Color Bar New */}
                      <L83GComponentCode />
                      {/* End L83G Icon Color Bar New */}
                      <LegoL83GComponent />

                      {/* L83H Icon Color Bar New */}
                      <L83HComponentCode />
                      {/* End L83H Icon Color Bar New */}
                      <LegoL83HComponent />

                      {/* L83I Icon Color Bar New */}
                      <L83IComponentCode />
                      {/* End L83I Icon Color Bar New */}
                      <LegoL83IComponent />

                      {/* L83J Icon Color Bar New */}
                      <L83JComponentCode />
                      {/* End L83J Icon Color Bar New */}
                      <LegoL83JComponent />

                      {/* L83K Icon Color Bar New */}
                      <L83KComponentCode />
                      {/* End L83K Icon Color Bar New */}
                      <LegoL83KComponent />

                      {/* L83L Icon Color Bar New */}
                      <L83LComponentCode />
                      {/* End L83L Icon Color Bar New */}
                      <LegoL83LComponent />
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <label> L83M (L101) Icon Color Bar New</label>
                    </div>
                    <div className="card-body">
                      {/* L101 Icon Color Bar New */}
                      <L101ComponentCode />
                      {/* End L83L Icon Color Bar New */}
                      <LegoL101Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L84 Block</label>
                    </div>
                    <div className="card-body">
                      {/* L84 Block */}
                      <L84ComponentCode />
                      {/* End L84 Block */}
                      <LegoL84Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L85 Color Bar Top Content Block</label>
                    </div>
                    <div className="card-body">
                      {/* L85 Color Bar Top Content Block */}
                      <L85ComponentCode />
                      {/* End L85 Color Bar Top Content Block */}
                      <LegoL85Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L86 Table New Lego</label>
                    </div>
                    <div className="card-body">
                      {/* L86 Table New Lego */}
                      <L86ComponentCode />
                      {/* End L86 Table New Lego */}
                      <LegoL86Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L87 Table 5 GrayScale</label>
                    </div>
                    <div className="card-body">
                      {/* L87 Table 5 GrayScale */}
                      <L87ComponentCode />
                      {/* End L87 Table 5 GrayScale */}
                      <LegoL87Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L88 List with Background Color</label>
                    </div>
                    <div className="card-body">
                      {/* L88 List with Background Color */}
                      <L88ComponentCode />
                      {/* End L88 List with Background Color */}
                      <LegoL88Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L88A & L88B Colored rectangle Footer Box</label>
                    </div>
                    <div className="card-body">
                      {/* L88A Colored Bold rectangle Footer Box  */}
                      <L88AComponentCode />
                      {/* End L88A Bold rectangle Footer Box  */}
                      <LegoL88AComponent />
                      {/* L88B Colored Bold rectangle Footer Box  */}
                      <L88BComponentCode />
                      {/* End L88B Bold rectangle Footer Box  */}
                      <LegoL88BComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>
                        L89 Content Block with Title and Background Color
                      </label>
                    </div>
                    <div className="card-body">
                      {/* L89 Content Block with Title and Background Color */}
                      <L89ComponentCode />
                      {/* End L89 Content Block with Title and Background Color */}
                      <LegoL89Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>
                        L89A Content  with Icon and BottomBorder
                      </label>
                    </div>
                    <div className="card-body">
                      {/* L89A Content  with Icon and BottomBorder */}
                      <L89AComponentCode />
                      {/* End L89A Content  with Icon and BottomBorder */}
                      <LegoL89AComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L90 Color Top Bar with special icon list</label>
                    </div>
                    <div className="card-body">
                      {/* L90 Color Top Bar with special icon list */}
                      <L90ComponentCode />
                      {/* End L90 Color Top Bar with special icon list */}
                      <LegoL90Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L91 Bold Lead In with List Small font</label>
                    </div>
                    <div className="card-body">
                      {/* L91 Bold Lead In with List Small font */}
                      <L91ComponentCode />
                      {/* End L91 Bold Lead In with List Small font */}
                      <LegoL91Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>
                        L92 Bold Lead In Title, Name, Job Title, phone number
                        and URL
                      </label>
                    </div>
                    <div className="card-body">
                      {/* L92 Bold Lead In Title, Name, Job Title, phone number and URL */}
                      <L92ComponentCode />
                      {/* End L92 Bold Lead In Title, Name, Job Title, phone number and URL */}
                      <LegoL92Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>
                        L93 Two equal elements side by side Specialty Lego
                      </label>
                    </div>
                    <div className="card-body">
                      {/* L93 Two equal elements side by side Specialty Lego */}
                      <L93ComponentCode />
                      {/* End L93 Two equal elements side by side Specialty Lego */}
                      <LegoL93Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L95 4 horizontal color boxes with titles</label>
                    </div>
                    <div className="card-body">
                      {/* L95 4 horizontal color boxes with titles */}
                      <L95ComponentCode />
                      {/* End L95 4 horizontal color boxes with titles */}
                      <LegoL95Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>
                        L96 Content Block with Bullets and No Background Color
                      </label>
                    </div>
                    <div className="card-body">
                      {/* L96 Content Block with Bullets and No Background Color */}
                      <L96ComponentCode />
                      {/* End L96  Content Block with Bullets and No Background Color */}
                      <LegoL96Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L98 Bold Italic text & content with Link</label>
                    </div>
                    <div className="card-body">
                      {/* L98 Bold Italic text & content with Link */}
                      <L98ComponentCode />
                      {/* End L98 Bold Italic text & content with Link */}
                      <LegoL98Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L99 Resource and Description New</label>
                    </div>
                    <div className="card-body">
                      {/* L99 Resource and Description New */}
                      <L99ComponentCode />
                      {/* End L99 Resource and Description New */}
                      <LegoL99Component />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L99A App Icon and Description New</label>
                    </div>
                    <div className="card-body">
                      {/* L99A App Icon and Description New */}
                      <L99AComponentCode />
                      {/* End L99A App Icon and Description New */}
                      <LegoL99AComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L99B App Icon and Description New</label>
                    </div>
                    <div className="card-body">
                      {/* L99B Resource and Description Image Top and Centered New */}
                      <L99BComponentCode />
                      {/* End L99B Resource and Description Image Top and Centered New */}
                      <LegoL99BComponent />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <label>L99C Resource and Description Image Top and Centered - 2 L99B side by side</label>
                    </div>
                    <div className="card-body">
                      {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                      <L99CComponentCode />
                      {/* End L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                      <LegoL99CComponent />
                    </div>
                  </div>

                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LegosRefactored;
