import React from "react";
import { CodeBlock } from "react-code-block";
import { useCopyToClipboard } from "react-use";
import "@fortawesome/fontawesome-svg-core/styles.css";
import YouTube from "react-youtube";

function LegoL99CComponent({ code, language }) {
  code = `{/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
                                    <div className="lego l099C legoMargin5">
                                        <div className="dualLegoContainer">

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B legoMargin5">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/4bc561d6c6a8eef51477822c9cca3015.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p className="descriptionHeader">Sleep Hygiene Made Simple</p>
                                                        <p>Practicing quality “sleep hygiene” provides so many positive
                                                            benefits
                                                            you can take advantage of right away.</p>
                                                        <p>Click image or <a
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End L99B Resource and Description Image Top and Centered New */}

                                            {/* L99B Resource and Description Image Top and Centered New */}
                                            <div className="lego l099B legoMargin5">
                                                <div className="legoContainer">
                                                    <div className="imgContainer">
                                                        <a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">
                                                            <img
                                                                className=""
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/4bc561d6c6a8eef51477822c9cca3015.jpeg"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="contentContainer">
                                                        <p className="descriptionHeader">Sleep Hygiene Made Simple</p>
                                                        <p>Practicing quality “sleep hygiene” provides so many positive
                                                            benefits
                                                            you can take advantage of right away.</p>
                                                        <p>Click image or <a
                                                            href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">this
                                                            link</a> to view.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}`;
  language = "jsx";
  const [state, copyToClipboard] = useCopyToClipboard();

  const copyCode = () => {
    // Logic to copy `code`
    copyToClipboard(code);
  };

  return (
    <CodeBlock code={code} language={language}>
      <div className="relative">
        <CodeBlock.Code className="bg-black">
          <div className="table-row">
            <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
            <CodeBlock.LineContent className="table-cell">
              <CodeBlock.Token />
            </CodeBlock.LineContent>
          </div>
        </CodeBlock.Code>
        <div className="buttonContainer">
          <button className="btn btn-primary ml-auto" onClick={copyCode}>
            <i className="fa-solid fa-copy"></i>{" "}
            {state.value ? "Copied!" : "Copy code"}
          </button>
        </div>
      </div>
    </CodeBlock>
  );
}

export default LegoL99CComponent;
