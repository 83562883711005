import React from "react";

function L99CComponentCode() {
  return (
    <div>
      {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
      <div className="lego l099C legoMargin5">
        <div className="dualLegoContainer">

          {/* L99B Resource and Description Image Top and Centered New */}
          <div className="lego l099B legoMargin5">
            <div className="legoContainer">
              <div className="imgContainer">
                <a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">
                  <img
                      className=""
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/4bc561d6c6a8eef51477822c9cca3015.jpeg"
                  />
                </a>
              </div>
              <div className="contentContainer">
                <p className="descriptionHeader">Sleep Hygiene Made Simple</p>
                <p>Practicing quality “sleep hygiene” provides so many positive
                  benefits
                  you can take advantage of right away.</p>
                <p>Click image or <a
                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">this
                  link</a> to view.</p>
              </div>
            </div>
          </div>
          {/* End L99B Resource and Description Image Top and Centered New */}

          {/* L99B Resource and Description Image Top and Centered New */}
          <div className="lego l099B legoMargin5">
            <div className="legoContainer">
              <div className="imgContainer">
                <a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">
                  <img
                      className=""
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/4bc561d6c6a8eef51477822c9cca3015.jpeg"
                  />
                </a>
              </div>
              <div className="contentContainer">
                <p className="descriptionHeader">Sleep Hygiene Made Simple</p>
                <p>Practicing quality “sleep hygiene” provides so many positive
                  benefits
                  you can take advantage of right away.</p>
                <p>Click image or <a
                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/LWC+Sleep+Log.pdf">this
                  link</a> to view.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* L99C Resource and Description Image Top and Centered - 2 L99B side by side */}
    </div>
  );
}

export default L99CComponentCode;
